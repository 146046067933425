import React, { useState, memo, useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { TextField, Paper, MenuItem, Typography } from "@mui/material";
import { obtenerComunas } from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import CustomDate from "../../utils/CustomDate";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import {
  iniciarDescargaAgente,
  verificarEstadoDescargaAgente,
  descargarArchivoAgente,
} from "../../services/ReportesServices";

const ConsultaMasivaAgentes = () => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  const AGENTE_ASIGNADO = userInfo ? userInfo.AGENTE_ASIGNADO : null;
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [isLoadingConsulta, setIsLoadingConsulta] = useState(false);
  const [tipoOrden, setTipoOrden] = useState("todas");
  const [estadoEntrega, setEstadoEntrega] = useState("todas");
  const [comunas, setComunas] = useState([]);
  const [comunaOrigen, setComunaOrigen] = useState(null);
  const [comunaDestino, setComunaDestino] = useState(null);

  const formatFechaToYYYYMMDD = (date) => {
    return date.toISOString().split("T")[0];
  };

  const validarCampos = () => {
    if (!comunaOrigen) {
      toast.error("Debes seleccionar una comuna de origen.");
      return false;
    }
    if (!comunaDestino) {
      toast.error("Debes seleccionar una comuna de destino.");
      return false;
    }
    if (!fechaDesde) {
      toast.error("Debes seleccionar la fecha desde.");
      return false;
    }
    if (!fechaHasta) {
      toast.error("Debes seleccionar la fecha hasta.");
      return false;
    }
    if (fechaDesde && fechaHasta && fechaDesde > fechaHasta) {
      toast.error("La fecha 'Desde' no puede ser posterior a la fecha 'Hasta'.");
      return false;
    }

    const diffTime = Math.abs(fechaHasta - fechaDesde);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 30) {
      toast.error("El rango entre las fechas debe ser de máximo 30 días.");
      return false;
    }
    /* if (diffDays > 15) {
      toast.error("El rango entre las fechas debe ser de máximo 15 días."); */
    return true;
  };

  const DescargarPeriodo = async () => {
    if (!validarCampos()) return;
    setIsLoadingConsulta(true);
    const data = {
      agenteAsignado: AGENTE_ASIGNADO,
      tipoOrden: tipoOrden,
      estadoEntrega: estadoEntrega,
      comunaOrigen: comunaOrigen?.IATA,
      comunaDestino: comunaDestino?.IATA,
      fechaDesde: fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null,
      fechaHasta: fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null,
    };

    try {
      const { downloadId } = await iniciarDescargaAgente(data);
      if (downloadId) {
        await verificarDescarga(downloadId);
      } else {
        console.error("No se recibió un downloadId válido");
        toast.error("Error al iniciar la descarga");
      }
    } catch (error) {
      console.error("Error durante la descarga:", error);
      toast.error("Error al descargar los datos");
    } finally {
      setIsLoadingConsulta(false);
    }
  };

  const verificarDescarga = async (downloadId) => {
    try {
      let estado = await verificarEstadoDescargaAgente(downloadId);
      while (estado.estado !== "completado") {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        estado = await verificarEstadoDescargaAgente(downloadId);
      }
      descargarArchivoAgente(downloadId);
    } catch (error) {
      console.error("Error al verificar el estado de la descarga:", error);
      toast.error("Error al verificar el estado de la descarga.");
    }
  };

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              label="Comuna Origen"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((comuna) => ({
                  IATA: comuna.IATA,
                  nombre: comuna.NOMBRE + " (" + comuna.IATA + ")",
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaOrigen}
              onChange={(event, newValue) => setComunaOrigen(newValue)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              label="Comuna Destino"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((comuna) => ({
                  IATA: comuna.IATA,
                  nombre: comuna.NOMBRE + " (" + comuna.IATA + ")",
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaDestino}
              onChange={(event, newValue) => setComunaDestino(newValue)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Tipo de orden"
              value={tipoOrden}
              onChange={(e) => setTipoOrden(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGA">Entregas</MenuItem>
              <MenuItem value="RETIRO">Retiros</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Estado actual"
              value={estadoEntrega}
              onChange={(e) => setEstadoEntrega(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGADAS">Entregadas</MenuItem>
              <MenuItem value="NO ENTREGADAS">No Entregadas</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomDate
              label="Fecha Desde"
              value={fechaDesde}
              onChange={(newValue) => {
                setFechaDesde(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomDate
              label="Fecha Hasta"
              value={fechaHasta}
              onChange={(newValue) => {
                setFechaHasta(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#031042",
                },
                marginTop: 1,
              }}
              onClick={() => DescargarPeriodo(true)}
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoadingConsulta}
            >
              {isLoadingConsulta ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Descargar"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {/* Mensaje de espera durante la descarga */}
      {isLoadingConsulta && (
        <Typography variant="body2" color="red" align="center" sx={{ marginTop: 2 }}>
          La descarga puede tardar bastante dependiendo del rango de fechas seleccionado. Por ejemplo, para un mes con
          aproximadamente 50,000 registros, el proceso puede tomar cerca de 2 minutos. Por favor, espera...
        </Typography>
      )}
    </Container>
  );
};

export default memo(ConsultaMasivaAgentes);
