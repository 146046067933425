import React, { memo, useState } from "react";
import ImprimirEtiqueta from "./ImprimirEtiqueta";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ImprimirEtiquetaOrdenes = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "reImprimir":
        return <ImprimirEtiqueta />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={3}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("reImprimir")}
          sx={{
            backgroundColor: selectedModule === "reImprimir" ? "#041562" : undefined,
            color: selectedModule === "reImprimir" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "reImprimir" ? "#DA251C" : undefined,
            },
          }}
        >
          Re-imprimir Orden
        </Button>

      </ButtonGroup>
      <Box mt={3}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(ImprimirEtiquetaOrdenes);
