import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import DescargaManifiestoPorProceso from "../../components/OperacionesComponentes/componentesDescargaManifiestos/DescargaManifiestoporProceso";
import DescargaManifiestosRango from "../../components/OperacionesComponentes/componentesDescargaManifiestos/DescargaManifiestosRango";
import DescargaManifiestoPorId from "../../components/OperacionesComponentes/componentesDescargaManifiestos/DescargaManifiestosPorId";
import DescargaManifiestoPorPatente from "../../components/OperacionesComponentes/componentesDescargaManifiestos/DescargaManifiestoPorPatente";

const DescargaManifiestos = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const cod_cliente = userInfo ? userInfo.cod_cliente : null;
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;

  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    // Mostrar el módulo seleccionado normalmente
    switch (selectedModule) {
      case "DescargaManifiestoPorId":
        return <DescargaManifiestoPorId />;
      case "DescargaManifiestoPorProceso":
        return <DescargaManifiestoPorProceso />;
      case "DescargaManifiestoPorPatente":
        return <DescargaManifiestoPorPatente />;
      case "DescargaManifiestosRango":
        return <DescargaManifiestosRango />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={1}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        {tipo_usuario !== "CLIENTE" &&
          tipo_usuario !== "AGENTE" &&
          tipo_usuario !== "AGENTE_HUB" &&
          tipo_usuario !== "AGENTE_ADMIN" && (
            <Button
              onClick={() => setSelectedModule("DescargaManifiestoPorId")}
              sx={{
                backgroundColor: selectedModule === "DescargaManifiestoPorId" ? "#041562" : undefined,
                color: selectedModule === "DescargaManifiestoPorId" ? "#fff" : undefined,
                "&:hover": {
                  backgroundColor: selectedModule === "DescargaManifiestoPorId" ? "#DA251C" : undefined,
                },
              }}
            >
              Descarga Manifiesto Por ID
            </Button>
          )}
        <Button
          onClick={() => setSelectedModule("DescargaManifiestoPorProceso")}
          sx={{
            backgroundColor: selectedModule === "DescargaManifiestoPorProceso" ? "#041562" : undefined,
            color: selectedModule === "DescargaManifiestoPorProceso" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "DescargaManifiestoPorProceso" ? "#DA251C" : undefined,
            },
          }}
        >
          Descarga Manifiesto Por Proceso
        </Button>
        {tipo_usuario === "ADMIN" && (
          <Button
            onClick={() => setSelectedModule("DescargaManifiestoPorPatente")}
            sx={{
              backgroundColor: selectedModule === "DescargaManifiestoPorPatente" ? "#041562" : undefined,
              color: selectedModule === "DescargaManifiestoPorPatente" ? "#fff" : undefined,
              "&:hover": {
                backgroundColor: selectedModule === "DescargaManifiestoPorPatente" ? "#DA251C" : undefined,
              },
            }}
          >
            Descarga Manifiesto Por Patente
          </Button>
        )}
        {(cod_cliente === "549" || tipo_usuario === "ADMIN" || cod_cliente === "632") && (
          <Button
            onClick={() => setSelectedModule("DescargaManifiestosRango")}
            sx={{
              backgroundColor: selectedModule === "DescargaManifiestosRango" ? "#041562" : undefined,
              color: selectedModule === "DescargaManifiestosRango" ? "#fff" : undefined,
              "&:hover": {
                backgroundColor: selectedModule === "DescargaManifiestosRango" ? "#DA251C" : undefined,
              },
            }}
          >
            {cod_cliente === "549" ? "Descarga por Rango de Fecha" : "Descarga Manifiesto Retorno Por Rango"}
          </Button>
        )}
      </ButtonGroup>
      <Box mt={1}>{renderModule()}</Box>
    </Box>
  );
};

export default DescargaManifiestos;
