import React, { useState, memo } from "react";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { obtenerMovimientosPage } from "../../services/ordenesServices";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import ReusableTableMovimientos from "../../components/FacturacionComponentes/componentesMovimientos/ReusableTableMovimientos";
import { Paper, Container, Grid } from "@mui/material";

const PortalMovimientos = () => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;

  const [odValue, setOdValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [movimientos, setMovimientos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const customHeaders = [
    { field: "OD", headerName: "OD", width: 150 },
    { field: "NOMBRE", headerName: "Usuario", width: 250 },
    { field: "NOMBRE_CHOFER", headerName: "Chofer", width: 200 },
    { field: "PROCESO", headerName: "Proceso", width: 450 },
    { field: "FH_SYS", headerName: "Fecha del evento", width: 250 },
    {
      field: "actions",
      headerName: "Datos",
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpen(params.row)}>
          <FolderIcon style={{ color: "#DA251C" }} />
        </IconButton>
      ),
    },
  ];

  const tryParseJSON = (jsonString) => {
    console.log("el JSON:", jsonString);

    try {
      const result = JSON.parse(jsonString);
      console.log("Parsed JSON:", result);
      return result;
    } catch (e) {
      console.error("Error parsing JSON:", e);
      return null;
    }
  };

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const handleOpen = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const fetchMovimientos = async () => {
    setIsLoading(true);
    try {
      const data = await obtenerMovimientosPage(odValue);
      console.log("MOV:",data);
      if (data.length === 0) {
        toast.warning("No se encontraron movimientos para la OD especificada.");
      } else {
        toast.success("Datos cargados exitosamente.");
        const dataWithIds = data.map((row, index) => ({ id: index, ...row }));
        setMovimientos(dataWithIds);
      }
    } catch (error) {
      toast.error("Error al obtener los movimientos");
    } finally {
      setIsLoading(false);
    }
  };

  const ensureDataPrefix = (imageData) => {
    if (!imageData.startsWith("data:image")) {
      return `data:image/png;base64,${imageData}`;
    }
    return imageData;
  };

  const isBase64Image = (value) => {
    return typeof value === "string" && (value.startsWith("data:image") || /^[A-Za-z0-9+/=]+z/.test(value));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchMovimientos();
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Paper style={{ padding: 10, marginBottom: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Buscar por OD"
                variant="outlined"
                value={odValue}
                onChange={(e) => setOdValue(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                fullWidth
                margin="dense"
                variant="outlined"
                color="primary"
                onClick={fetchMovimientos}
                style={{
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  marginTop: 10,
                }}
              >
                {isLoading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {movimientos.length > 0 && (
          <ReusableTableMovimientos
            columns={customHeaders}
            rows={movimientos}
            loading={isLoading}
            searchField="NOMBRE"
          />
        )}
      </Container>
      <Modal open={isModalOpen} onClose={handleClose}>
        {selectedRow ? (
          <div
            style={{
              padding: "10px",
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxHeight: "60vh",
              overflowY: "auto",
              borderRadius: "10px",
            }}
          >
            <h3 style={{ textAlign: "center", marginBottom: "30px" }}>Datos Digitados</h3>
            <Grid container spacing={1}>
              {(() => {
                const instruccionData = tryParseJSON(selectedRow.INSTRUCCION);
                if (instruccionData) {
                  const dataElements = Object.entries(instruccionData)
                    .filter(([key, value]) => !isBase64Image(value))
                    .map(([key, value], index) => {
                      const uniqueKey = `data-${key}-${index}`;
                      return (
                        <Grid item xs={12} sm={6} md={4} key={uniqueKey}>
                          <Typography variant="body1" style={{ margin: "5px 0", fontWeight: "bold" }}>
                            {`${formatKey(key)}: ${value}`}
                          </Typography>
                        </Grid>
                      );
                    });

                  const imageElements = Object.entries(instruccionData)
                    .filter(
                      ([key, value]) =>
                        typeof value === "string" &&
                        (value.startsWith("data:image") || value.match(/^[A-Za-z0-9+/=]+z/))
                    )
                    .map(([key, value], index) => {
                      const uniqueKey = `image-${key}-${index}`;
                      return (
                        <Grid item xs={12} key={uniqueKey}>
                          {" "}
                          <img
                            src={ensureDataPrefix(value)}
                            alt={`Imagen de ${key}`}
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                        </Grid>
                      );
                    });

                  return [...dataElements, ...imageElements];
                } else {
                  return (
                    <Typography variant="body1" style={{ margin: "5px 0", fontWeight: "bold" }}>
                      Error al cargar los datos
                    </Typography>
                  );
                }
              })()}
            </Grid>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </Modal>
    </>
  );
};

export default memo(PortalMovimientos);

