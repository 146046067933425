import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const iniciarDescargaCliente = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consultaCliente/DescargarPeriodo`, data);
    if (response.data.downloadId) {
      console.log(response.data);
      return response.data;
    } else {
      throw new Error("Download ID not received");
    }
  } catch (error) {
    console.error("Error al iniciar la descarga:", error);
    throw error;
  }
};

export const verificarEstadoDescargaCliente = async (downloadId) => {
  console.log(downloadId);
  try {
    const response = await axios.get(`${BASE_API_URL}consultaCliente/estadoDescarga/${downloadId}`);
    return response.data;
  } catch (error) {
    console.error("Error al verificar el estado de la descarga:", error);
    throw error;
  }
};

export const descargarArchivoCliente = (downloadId) => {
  try {
    const url = `${BASE_API_URL}consultaCliente/descargar/${downloadId}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Reporte.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
    throw error;
  }
};

/* buscar od y codigo de cliente */
export const buscarOrdenesClientes = async ({ searchValue, cod_cliente }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}clientes/buscarOrdenesClientes`, {
      searchValue,
      cod_cliente,
    });
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos consolidados", error);
    throw error;
  }
};

export const editOrdenDataClientes = async (ID, editedData) => {
  try {
    const {
      OD,
      OD_PAPEL,
      FH_DIGITACION,
      BULTOS,
      COD_CLIENTE,
      USUARIO,
    } = editedData;

    const data = {
      OD,
      OD_PAPEL,
      FH_DIGITACION,
      BULTOS,
      COD_CLIENTE,
      USUARIO,
    };
    const response = await axios.put(`${BASE_API_URL}clientes/editOrdenesClientes/${ID}`, data);
    return response.data;
  } catch (error) {
    console.error("Error editando datos de la orden", error);
    throw error;
  }
};

