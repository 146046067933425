import React, { useState } from "react";
import { Paper, Grid, TextField, Button } from "@mui/material";
import { getOrdenesPendientesEntrada } from "../../../services/PendientesEntradaSalida";
import TablaOrdenes from "./../componentesManifiestoRutaPorOd/TablaOrdenes";
import { toast } from "react-toastify";

const PendientesEntradaSantiago = () => {
  const [fechaSeleccionada1, setFechaSeleccionada1] = useState("");
  const [dataOrdenes, setDataOrdenes] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE_CLIENTE", headerName: "CLIENTE", width: 400 },
    { field: "CIUDAD_ORIGEN", headerName: "ORIGEN", width: 200 },
    { field: "CIUDAD_DESTINO", headerName: "DESTINO", width: 200 },
    { field: "ID_MANIFIESTO", headerName: "MANIF. AGENTE", width: 150 },
  ];

  const handleChangeFecha1 = async (e, newValue) => {
    setFechaSeleccionada1(e.target.value);
  };

  const consultar = async () => {
    if (!fechaSeleccionada1) {
      toast.error("Faltan datos");
      return;
    }

    try {
      setLoading(true);
      const datos = {
        FECHA1: fechaSeleccionada1,
      };
      const OrdenesData = await getOrdenesPendientesEntrada(datos);
      if (OrdenesData.length > 0) {
        setDataOrdenes(OrdenesData);
        //(OrdenesData);
      } else {
        setDataOrdenes([]);
        toast.warning("No existen pendientes para este día");
      }
    } catch (error) {
      console.error("Error al consultar datos:", error);
      toast.error("Ocurrió un error al consultar los datos");
    } finally {
      setLoading(false);
    }
  };

  const cancelar = async () => {
    reset();
  };

  const reset = () => {
    setFechaSeleccionada1("");
    setDataOrdenes([]);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="FECHA PROCESO REALIZADO"
              value={fechaSeleccionada1}
              onChange={handleChangeFecha1}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button
              style={{ backgroundColor: "#041562", color: "#FFFFFF", width: "100%", height: "100%" }}
              className="boton1"
              size="medium"
              variant="contained"
              color="primary"
              onClick={consultar}
            >
              CONSULTAR
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button
              style={{ backgroundColor: "#DA251C", width: "100%", height: "100%" }}
              className="boton1"
              size="medium"
              variant="contained"
              onClick={cancelar}
            >
              CANCELAR
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ marginTop: "10px" }}>
            <TablaOrdenes rows={dataOrdenes} columns={columns} loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default PendientesEntradaSantiago;
