import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { obtenerEventos, editConsolidadoData, deleteConsolidadoData } from "../../services/GestorEventosServices";
import TableEventos from "../../components/DespachoComponentes/componentesEventos/tableEventos";
import EditModalEventosNuevo from "../../components/DespachoComponentes/componentesEventos/EditModalEventosNuevo";
import DeleteModalEventos from "../../components/DespachoComponentes/componentesEventos/DeleteModalEventos";
import { IconButton, Button, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Modal, Box, Fade } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const GestorEventos = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo.id);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEventosForDelete, setSelectedEventosForDelete] = useState(null);
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEventosForEdit, setSelectedEventosForEdit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const onEditClick = (evento) => {
    setSelectedEventosForEdit(evento);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setSelectedEventosForEdit(null);
  };

  const handleOpenModal = (image) => {
    const formattedImage = image.startsWith("data:image/jpeg;base64,") ? image : `data:image/jpeg;base64,${image}`;
    setModalImage(formattedImage);
    setModalOpen(true);
  };

  const handleCloseModalImage = () => {
    setModalImage(null);
    setModalOpen(false);
  };

  const BuscarEventos = async () => {
    setLoading(true);
    if (!searchTerm) {
      toast.warning("Por favor, introduce un valor de búsqueda.");
      setLoading(false);
      return;
    }
    try {
      const rawData = await obtenerEventos({ BUSCAR: searchTerm });
      setEventos(rawData);
      toast.success("Datos encontrados.");
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    } finally {
      setLoading(false);
      setCreateModalOpen(false);
    }
  };

  const onDeleteClick = (evento) => {
    setSelectedEventosForDelete(evento);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const deleteData = {
        id: selectedEventosForDelete.ID,
        USUARIO: userInfo,
        OD: selectedEventosForDelete.OD,
        OD_PAPEL: selectedEventosForDelete.OD_PAPEL,
        NOMBRE_RECIBE: selectedEventosForDelete.NOMBRE_RECIBE,
        RUT: selectedEventosForDelete.RUT,
        FH_GESTION: selectedEventosForDelete.FH_GESTION,
        NOMBRE_CHOFER: selectedEventosForDelete.NOMBRE_CHOFER,
        COD_ESTADO_DESCRIPCION:selectedEventosForDelete.COD_ESTADO_DESCRIPCION,
        FOTO1: selectedEventosForDelete.foto1,
        FOTO2: selectedEventosForDelete.foto2,
        FOTO3: selectedEventosForDelete.foto3,
        
      };
      console.log(deleteData);
      await deleteConsolidadoData(deleteData);
      const filteredEventos = eventos.filter((evento) => evento.ID !== selectedEventosForDelete.ID);
      setEventos(filteredEventos);
      setDeleteModalOpen(false);
      toast.success("Eventos eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el Eventos:", error);
      toast.error("Error al eliminar el Eventos");
    }
  };

  const handleSaveEdit = async (updatedEventos) => {
    const { ID, OD, OD_PAPEL, NOMBRE_RECIBE, RUT, FH_GESTION, COD_ESTADO, NOTA, foto1, foto2, foto3 } = updatedEventos;
    const dataToSend = {
      ID,
      OD,
      OD_PAPEL,
      NOMBRE_RECIBE,
      RUT,
      FH_GESTION,
      COD_ESTADO,
      NOTA,
      foto1,
      foto2,
      foto3,
      USUARIO: userInfo,
    };
    try {
      await editConsolidadoData(dataToSend);
      toast.success("Datos actualizados con éxito.");
      handleCloseModal();
      const updatedEvents = eventos.map((evt) => (evt.ID === ID ? { ...evt, ...updatedEventos } : evt));
      setEventos(updatedEvents);
    } catch (error) {
      console.error("Error al actualizar datos: ", error);
      toast.error("Error al actualizar datos: " + (error.message || "Por favor, inténtalo de nuevo."));
    }
  };

  const renderValue = (value, key) => {
    if (["foto1", "foto2", "foto3"].includes(key) && typeof value === "string" && value !== "FALSE") {
      return <VisibilityIcon onClick={() => handleOpenModal(value)} style={{ cursor: "pointer" }} />;
    } else if (["foto1", "foto2", "foto3"].includes(key) && value === "FALSE") {
      return "No hay imagen.";
    }
    return value;
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => onEditClick(params.row)}>
            <EditIcon style={{ color: "#041562" }} />
          </IconButton>
          <IconButton onClick={() => onDeleteClick(params.row)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
      width: 100,
    },
    { field: "NOMBRE_CLIENTE", headerName: "Nombre Cliente", width: 230 },
    { field: "NOMBRE_CHOFER", headerName: "Nombre Chofe", width: 150 },
    { field: "NOMBRE_DESTINO", headerName: "Nombre Destino", width: 150 },
    { field: "DIRECCION_DESTINO", headerName: "Destino", width: 150 },
    { field: "COMUNA_DESTINO", headerName: "Comuna Destino", width: 150 },
    {
      field: "foto1",
      headerName: "Foto 1",
      width: 130,
      renderCell: (params) => renderValue(params.value, "foto1"),
    },
    {
      field: "foto2",
      headerName: "Foto 2",
      width: 130,
      renderCell: (params) => renderValue(params.value, "foto2"),
    },
    {
      field: "foto3",
      headerName: "Foto 3",
      width: 130,
      renderCell: (params) => renderValue(params.value, "foto3"),
    },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "OD_PAPEL", headerName: "OD papel", width: 100 },
    { field: "NOMBRE_RECIBE", headerName: "Recibe", width: 180 },
    { field: "RUT", headerName: "Rut", width: 150 },
    { field: "FH_GESTION", headerName: "Fecha Gestion", width: 180 },
    { field: "COD_ESTADO_DESCRIPCION", headerName: "Estado", width: 250 },
    { field: "ORIGEN_CERTIFICACION", headerName: "Origen", width: 350 },
    { field: "CONEXION", headerName: "Conexion", width: 350 },
    { field: "NOTA", headerName: "Nota", width: 200 },
  ];

  return (
    <div>
      <div>
        <TextField
          style={{
            marginTop: "10px",
            marginBottom: "16px",
            width: "150px",
            height: "40px",
            marginRight: "10px",
            boxSizing: "border-box",
          }}
          inputProps={{
            style: {
              height: "20px",
              padding: "10px",
            },
          }}
          type="search"
          variant="filled"
          size="small"
          placeholder="OD/OD PAPEL"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          fullWidth
          margin="dense"
          variant="outlined"
          color="primary"
          onClick={BuscarEventos}
          style={{
            width: "150px",
            height: "40px",
            backgroundColor: "#041562",
            color: "#FFFFFF",
            marginTop: 10,
          }}
        >
          {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
        </Button>
      </div>
      {eventos.length > 0 && !createModalOpen && <TableEventos columns={columns} rows={eventos} loading={loading} />}
      {editModalOpen && selectedEventosForEdit && (
        <EditModalEventosNuevo
          show={editModalOpen}
          onClose={handleCloseModal}
          data={selectedEventosForEdit}
          onSave={handleSaveEdit}
        />
      )}
      {selectedEventosForDelete && (
        <DeleteModalEventos
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            setSelectedEventosForDelete(null);
          }}
          onConfirm={handleConfirmDelete}
        />
      )}

      <Modal open={modalOpen} onClose={handleCloseModalImage} closeAfterTransition>
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              outline: "none",
              boxShadow: 24,
              p: 4,
              backgroundColor: "background.paper",
            }}
          >
            <img src={modalImage} alt="Vista ampliada" style={{ maxWidth: "70vw", maxHeight: "70vh" }} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(GestorEventos);
