import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { obtenerClientes, actualizarPasswordUsuario } from "../../../services/PortalUsuariosService";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { obtenerComunas } from "../../../services/EventosManualServices";

const emptyFormData = {
  name: "",
  email: "",
  tipo_usuario: "",
  password_palabras: "",
  password: "",
  token: "",
  cod_cliente: "",
  canal: "",
  iata_sucursal: "",
  iata_agentes: "",
  ESTADO: "",
  permite_rebaja_manual: "",
  permite_rebaja_masiva: "",
  permite_modificacion_orden: "",
};

const CreateModalUsuarios = ({ open, onClose, onSave }) => {
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [formData, setFormData] = useState(emptyFormData);
  const [comunas, setComunas] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [comunasIataAgentes, setComunasIataAgentes] = useState([]);
  const [comunaSeleccionadaIataAgentes, setComunaSeleccionadaIataAgentes] = useState(null);

  /* Función para resetear el formulario */
  const resetForm = () => {
    setFormData(emptyFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditPasswordClick = async () => {
    const campoPassword = formData.password_palabras ? "password_palabras" : null;
    const nuevaPassword = formData[campoPassword];
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash, token } = response;
      const campoHash = formData.PASSWORD_HASH ? "PASSWORD_HASH" : "password";
      const campoToken = "token";
      setFormData((prevFormData) => ({
        ...prevFormData,
        [campoHash]: hash,
        [campoToken]: token,
      }));
      toast.success("Hash y Token actualizados con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash y el Token:", error);
      toast.error("Error al actualizar el Hash y el Token");
    }
  };

  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const listaClientes = await obtenerClientes();
        setClientes(listaClientes);
        setSelectedClient(null);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        toast.error("Error al cargar la lista de clientes.");
      }
    };
    cargarClientes();
  }, []);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  useEffect(() => {
    const cargarComunasIataAgentes = async () => {
      try {
        const comunasObtenidasIataAgentes = await obtenerComunas();
        setComunasIataAgentes(comunasObtenidasIataAgentes);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunasIataAgentes();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crear Nuevo Usuario</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nombre"
          type="text"
          fullWidth
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Correo"
          type="text"
          fullWidth
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Seleccione un tipo de usuario</InputLabel>
          <Select
            margin="dense"
            label="Seleccione un tipo de usuario"
            fullWidth
            variant="outlined"
            name="tipo_usuario"
            value={formData.tipo_usuario}
            onChange={handleChange}
          >
            <MenuItem value="ADMIN">ADMIN</MenuItem>
            <MenuItem value="JEFATURA">JEFATURA</MenuItem>
            <MenuItem value="CONTROL_CALIDAD">CONTROL DE CALIDAD</MenuItem>
            <MenuItem value="SAC">SAC</MenuItem>
            <MenuItem value="SUPERVISORA">SUPERVISORA</MenuItem>
            <MenuItem value="OPERACIONES">OPERACIONES</MenuItem>
            <MenuItem value="SUPERVISOR">SUPERVISOR OPERACIONES</MenuItem>
            <MenuItem value="FACTURACION">FACTURACION</MenuItem>
            <MenuItem value="CLIENTE">CLIENTE</MenuItem>
            <MenuItem value="AGENTE">AGENTE</MenuItem>
            <MenuItem value="AGENTE_HUB">AGENTE HUB</MenuItem>
            <MenuItem value="AGENTE_ADMIN">AGENTE ADMIN</MenuItem>
            <MenuItem value="GRAFICOS">GRAFICOS</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Password"
          type="text"
          fullWidth
          variant="outlined"
          name="password_palabras"
          value={formData.password_palabras}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                  <EditIcon style={{ color: "#041562" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Password hash"
          type="text"
          fullWidth
          variant="outlined"
          name="password"
          value={formData.password}
          onChange={handleChange}
          disabled
        />
        <TextField
          margin="dense"
          label="Token"
          type="text"
          fullWidth
          variant="outlined"
          name="token"
          value={formData.token}
          onChange={handleChange}
          disabled
        />
        <Autocomplete
          id="combo-box-cliente"
          options={clientes}
          getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
          isOptionEqualToValue={(option, value) => option.ID === value.ID}
          value={selectedClient || null}
          onChange={(event, newValue) => {
            setSelectedClient(newValue);
            setFormData((prev) => ({ ...prev, cod_cliente: newValue ? newValue.ID : "" }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ marginTop: "5px" }}
              label="Seleccione el cliente"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <TextField
          margin="dense"
          label="Canal"
          type="text"
          fullWidth
          variant="outlined"
          name="canal"
          value={formData.canal}
          onChange={handleChange}
        />
        <Autocomplete
          id="combo-box-iata_sucursal"
          options={comunas}
          getOptionLabel={(option) => option.NOMBRE}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={comunaSeleccionada}
          onChange={(event, newValue) => {
            setComunaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              iata_sucursal: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione una Iata sucursal" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <Autocomplete
          id="combo-box-iata_agentes"
          options={comunasIataAgentes}
          getOptionLabel={(option) => option.NOMBRE}
          isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
          value={comunaSeleccionadaIataAgentes}
          onChange={(event, newValue) => {
            setComunaSeleccionadaIataAgentes(newValue);
            setFormData((prev) => ({
              ...prev,
              iata_agentes: newValue ? newValue.IATA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione una Iata agentes" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Seleccione un estado</InputLabel>
          <Select
            margin="dense"
            label="Seleccione un estado"
            fullWidth
            variant="outlined"
            name="ESTADO"
            value={formData.ESTADO}
            onChange={handleChange}
          >
            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite rebaja manual?</InputLabel>
          <Select
            margin="dense"
            label="Permite rebaja manual?"
            fullWidth
            variant="outlined"
            name="permite_rebaja_manual"
            value={formData.permite_rebaja_manual}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite rebaja masiva?</InputLabel>
          <Select
            margin="dense"
            label="Permite rebaja masiva?"
            fullWidth
            variant="outlined"
            name="permite_rebaja_masiva"
            value={formData.permite_rebaja_masiva}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Permite modificacion orden?</InputLabel>
          <Select
            margin="dense"
            label="Permite modificacion orden?"
            fullWidth
            variant="outlined"
            name="permite_modificacion_orden"
            value={formData.permite_modificacion_orden}
            onChange={handleChange}
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            resetForm();
            onSave(formData);
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModalUsuarios;
