import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Dashboard, ChevronLeft, ChevronRight, Backspace } from "@mui/icons-material";
import logo from "../components/assets/img/logoCargoex.png";
import logoPequeño from "../components/assets/img/logoPequeño.png";
import Divider from "@mui/material/Divider";
import { IconButton } from "@mui/material";
import menuRules from "./menuRules";
import MenuConsulta from "./menuConsultas";
import MenuFacturacion from "./MenuFacturacion";
import MenuOperaciones from "./menuOperaciones";
import MenuSac from "./menuSac";
import MenuAdministracion from "./menuAdministracion";
import MenuControlCalidad from "./menuControlCalidad";
import MenuGraficos from "./menuGraficos";
import MenuClientes from "./menuClientes";
import MenuSeguridad from "./menuSeguridad";

function DesktopMenu({ tipoUsuario, isDrawerOpen, handleClick, openSubMenu, location, handleDrawer, handleLogout }) {
  const isVisible = (moduleName) => {
    return !!menuRules[tipoUsuario]?.[moduleName];
  };

  const hasVisibleSubmenus = (submenus) => {
    return submenus.some((submenu) => isVisible(submenu));
  };

  return (
    <List>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
          borderRadius: "10px",
          marginLeft: isDrawerOpen ? "0px" : "-9px",
          transition: "margin-left 0.5s ease",
          marginBottom: "10px",
          padding: "10px",
          marginTop: "8px",
          marginRight: "8px",
        }}
      >
        <img
          src={isDrawerOpen ? logo : logoPequeño}
          alt="Logo"
          style={{
            height: isDrawerOpen ? "50px" : "25px",
            transition: "transform 0.5s ease, height 0.5s ease, width 0.5s ease",
            width: isDrawerOpen ? "170px" : "40px",
            transform: isDrawerOpen ? "scale(1)" : "scale(0.5)",
          }}
        />
        <IconButton onClick={handleDrawer} sx={{ color: "#041562", padding: 0 }}>
          {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>

      {/* Inicio */}
      {isVisible("inicio") && (
        <ListItemButton
          component={Link}
          to="/"
          sx={{
            bgcolor: location.pathname === "/inicio" ? "white" : "transparent",
            color: location.pathname === "/inicio" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.875rem",
            },
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/inicio" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/inicio" ? "10px" : "0px",
            margin: location.pathname === "/inicio" ? "8px" : "0px",
            overflow: "hidden",
          }}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Inicio" />}
        </ListItemButton>
      )}
      {/* CONSULTAS */}
      <MenuConsulta
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* DESPACHO */}
      <MenuSac
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* FACTURACION */}
      <MenuFacturacion
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* OPERACIONES */}
      <MenuOperaciones
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* ADMINISTRACION */}
      <MenuAdministracion
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* CONTROL CALIDAD */}
      <MenuControlCalidad
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* GRAFICOS */}
      <MenuGraficos
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* CLIENTES */}
      <MenuClientes
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      {/* SEGURIDAD */}
      <MenuSeguridad
        isDrawerOpen={isDrawerOpen}
        openSubMenu={openSubMenu}
        handleClick={handleClick}
        isVisible={isVisible}
        location={location}
        hasVisibleSubmenus={hasVisibleSubmenus}
      />
      <Divider sx={{ bgcolor: "white", opacity: 0.2 }} />
      <ListItemButton
        onClick={handleLogout}
        sx={{
          bgcolor: "transparent",
          color: "white",
          "&:hover": {
            bgcolor: "white",
            color: "#041562",
            "& .MuiListItemIcon-root": { color: "#041562" },
          },
          ".MuiListItemIcon-root": {
            minWidth: isDrawerOpen ? "auto" : 25,
            mr: isDrawerOpen ? 2 : "auto",
            justifyContent: "center",
            marginLeft: isDrawerOpen ? -0.5 : -0.5,
          },
          borderRadius: location.pathname === "/" ? "10px" : "0px",
          margin: location.pathname === "/" ? "8px" : "0px",
          overflow: "hidden",
        }}
      >
        <ListItemIcon sx={{ color: "white" }}>
          <Backspace sx={{ color: "#DA251C" }} />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Cerrar sesión" />}
      </ListItemButton>
    </List>
  );
}

export default DesktopMenu;
