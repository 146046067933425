import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
//import { generarManifiestoPDF } from "../../services/ManifiestosServices";
import { getManifiestosProceso, getManifiesto } from "../../../services/ManifiestosServices";
import DescriptionIcon from "@mui/icons-material/Description";
import { getCiudades } from "../../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import * as XLSX from "xlsx";

const DescargaManifiestoPorProceso = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const cod_cliente = userInfo ? userInfo.cod_cliente : null;
  const ciudadAgente = userInfo ? userInfo.nombre_ciudad_agentes || userInfo.nombre_ciudad_sucursal : null;
  const iata_user = userInfo ? userInfo.iata_agentes || userInfo.iata_sucursal : null;
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [manifiestos, setManifiestos] = useState([]);
  const [ciudadesOption, setCiudadesOption] = useState([]);
  const [ciudad, setCiudad] = useState(
    tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN" ? ciudadAgente : null,
  );
  const [iataCiudad, setIataCiudad] = useState(null);
  const [proceso, setProceso] = useState(null);
  const [fecha, setFecha] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataCiudades = await getCiudades();
        const ciudadesOption = dataCiudades.map((ciudad) => ({
          value: ciudad.IATA,
          label: ciudad.NOMBRE,
        }));
        setCiudadesOption(ciudadesOption);
      } catch (error) {
        console.error("Error al obtener las ciudades:", error);
      }
    };

    fetchData();
  }, [userInfo]);

  const procesoOption = [
    { label: "ENTRADA AGENTE", value: "ENTRADAAGENTE" },
    { label: "ENTRADA ANDEN", value: "ENTRADAANDEN" },
    { label: "INGRESO SANTIAGO", value: "INGRESOSANTIAGO" },
    { label: "SALIDA CLIENTE (INHOUSE)", value: "INHOUSE" },
    { label: "SALIDA AGENTE", value: "SALIDAAGENTE" },
    { label: "SALIDA ANDEN", value: "SALIDAANDEN" },
    { label: "SALIDA RUTA", value: "SALIDARUTA" },
    { label: "RETORNO CLIENTE", value: "RETORNOCLIENTES" },
    { label: "CONSOLIDADO RETIROS", value: "MANIFIESTOAPP" },
  ];

  const opcionesFiltradas = procesoOption.filter((option) => {
    if (tipo_usuario === "AGENTE") {
      return ["ENTRADAAGENTE", "SALIDAAGENTE", "SALIDAANDEN", "SALIDARUTA"].includes(option.value);
    } else if (tipo_usuario === "CLIENTE" && (cod_cliente === "632" || cod_cliente === "463")) {
      return ["INHOUSE", "RETORNOCLIENTES"].includes(option.value);
    } else if (tipo_usuario === "CLIENTE" && cod_cliente === "549") {
      return ["INHOUSE", "MANIFIESTOAPP"].includes(option.value);
    } else if (tipo_usuario === "CLIENTE") {
      return ["INHOUSE"].includes(option.value);
    } else if (tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN") {
      return ["ENTRADAAGENTE", "SALIDAAGENTE", "SALIDAANDEN", "SALIDARUTA", "INHOUSE", "ENTRADAANDEN"].includes(
        option.value,
      );
    }
    return true; // Muestra todas las opciones
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setManifiestos([]);

      const data = (() => {
        switch (proceso) {
          case "SALIDARUTA":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN:
                tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                  ? iata_user
                  : iataCiudad,
              DESTINO:
                tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                  ? iata_user
                  : iataCiudad,
            };
          case "SALIDAAGENTE":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN:
                tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                  ? iata_user
                  : iataCiudad,
              //DESTINO: iata_user,
            };
          case "INGRESOSANTIAGO":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN:
                tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                  ? iata_user
                  : iataCiudad,
            };
          case "ENTRADAAGENTE":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN:
                tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                  ? iata_user
                  : iataCiudad,
            };
          case "SALIDAANDEN":
            if (
              !iataCiudad &&
              tipo_usuario !== "AGENTE" &&
              tipo_usuario !== "AGENTE_HUB" &&
              tipo_usuario !== "AGENTE_ADMIN"
            ) {
              return {
                PROCESO: proceso,
                FECHA: fecha,
                ORIGEN: iata_user,
              };
            } else {
              return {
                PROCESO: proceso,
                FECHA: fecha,
                ORIGEN: "SCL",
                DESTINO:
                  tipo_usuario === "AGENTE" || tipo_usuario === "AGENTE_HUB" || tipo_usuario === "AGENTE_ADMIN"
                    ? iata_user
                    : iataCiudad,
              };
            }
          case "MANIFIESTOAPP":
          case "INHOUSE":
          case "ENTRADAANDEN":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: iata_user,
            };
          case "RETORNOCLIENTES":
            return {
              PROCESO: proceso,
              FECHA: fecha,
              ORIGEN: iata_user,
            };
          default:
            return {};
        }
      })();

      const response = await getManifiestosProceso(data);

      // Convertir cod_cliente a número para la comparación
      const codClienteNumber = parseInt(userInfo.cod_cliente, 10);

      // Filtrar manifiestos solo si el tipo de usuario es CLIENTE
      const manifiestosFiltrados =
        tipo_usuario === "CLIENTE"
          ? response.filter((manifiesto) => manifiesto.ID_CLIENTE === codClienteNumber)
          : response;

      if (manifiestosFiltrados.length > 0) {
        toast.success("Datos cargados con éxito!");
        setManifiestos(manifiestosFiltrados);
      } else {
        console.log("No se encontraron detalles de manifiestos.");
        toast.warning("No se encontraron detalles de manifiestos.");
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      toast.error("Error al obtener detalles de la orden.");
    }
  };

  const handleChangeProceso = async (event, newValue) => {
    try {
      if (newValue.value === "INHOUSE" || newValue.value === "ENTRADAANDEN") {
        setCiudad(ciudadAgente);
      } else if (tipo_usuario !== "AGENTE" && tipo_usuario !== "AGENTE_HUB" && tipo_usuario !== "AGENTE_ADMIN") {
        setCiudad(null);
        setIataCiudad(null);
      }
      setProceso(newValue.value);
      setManifiestos([]);
    } catch (error) {}
  };

  const handleChangeCiudades = async (event, newValue) => {
    try {
      if (newValue === null) {
        setCiudad("");
        setIataCiudad("");
      } else {
        setCiudad(newValue.label);
        setIataCiudad(newValue.value);
      }
    } catch (error) {}
  };
  const handleChangeFecha = async (event, newValue) => {
    try {
      setFecha(event.target.value);
      setManifiestos([]);
    } catch (error) {}
  };

  const downloadExcel = async (id, tipo_usuario) => {
    const datos = {
      ID_MANIFIESTO: id,
    };
  
    const ordenesDataTabla = await getManifiesto(datos);
  
    let filteredData;
    let worksheetCols;
  
    if (tipo_usuario === "AGENTE") {
      // Si el tipo de usuario es AGENTE, usa las cabeceras y columnas específicas
      filteredData = ordenesDataTabla.map((item) => ({
        OD_PAPEL: item.OD_PAPEL,
        CLIENTE: item.CLIENTE,
        NOMBRE: item.NOMBRE,
        DIRECCIÓN: item.DIRECCION,
        RUT: item.RUT,
        TELÉFONO: item.TELEFONO,
        GUÍA: item.GUIA,
        BULTOS: item.BULTOS,
        PESO: item.PESO,
        ORIGEN: item.COMUNA_ORIGEN,
        DESTINO: item.COMUNA_DESTINO,
        TIPO_ORDEN: item.TIPO_ORDEN,
      }));
  
      // Definir el ancho de las columnas para AGENTE
      worksheetCols = [
        { wch: 10 }, // OD_PAPEL
        { wch: 25 }, // CLIENTE
        { wch: 40 }, // NOMBRE
        { wch: 40 }, // DIRECCIÓN
        { wch: 15 }, // RUT
        { wch: 15 }, // TELÉFONO
        { wch: 15 }, // GUÍA
        { wch: 10 }, // BULTOS
        { wch: 10 }, // PESO
        { wch: 25 }, // DESTINO (COMUNA)
        { wch: 15 }, // TIPO_ORDEN
      ];
    } else {
      // Si no es AGENTE, usa las cabeceras originales
      filteredData = ordenesDataTabla.map((item) => ({
        OD: item.OD,
        Fecha: new Date(item.FH_SYS).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        Observacion: item.NOTA,
        Destino: item.NOMBRE,
        Dirección: item.DIRECCION,
        "Comuna Destino": item.COMUNA_DESTINO,
        Via: item.VIA,
        Bultos: item.BULTOS,
        Kilos: item.PESO,
      }));
  
      // Definir el ancho de las columnas para el caso normal
      worksheetCols = [
        { wch: 10 }, // OD
        { wch: 15 }, // Fecha
        { wch: 40 }, // Observacion
        { wch: 40 }, // Destino
        { wch: 40 }, // Dirección
        { wch: 25 }, // Comuna Destino
        { wch: 15 }, // Via
        { wch: 10 }, // Bultos
        { wch: 10 }, // Kilos
      ];
    }
  
    // Crear la hoja de trabajo a partir de los datos filtrados
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
  
    // Aplicar los anchos de columnas
    worksheet["!cols"] = worksheetCols;
  
    // Crear un nuevo libro de trabajo y añadir la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HOJA1");
  
    // Descargar el archivo Excel
    XLSX.writeFile(workbook, `MANIFIESTO_${id}.xlsx`);
  };
  

  return (
    <div>
      <form>
        <Paper style={{ padding: "10px" }}>
          <Typography variant="h6" align="center" gutterBottom>
            Busqueda Manifiestos por Proceso
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                //value={proceso}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={opcionesFiltradas}
                onChange={handleChangeProceso}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="PROCESO" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                type="date"
                size="small"
                label="Fecha"
                value={fecha}
                onChange={handleChangeFecha}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {tipo_usuario !== "CLIENTE" ? (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Autocomplete
                  //disableClearable
                  value={ciudad}
                  //disablePortal
                  id="size-small-outlined-multi"
                  size="small"
                  options={ciudadesOption}
                  onChange={handleChangeCiudades}
                  disabled={
                    tipo_usuario === "AGENTE" ||
                    tipo_usuario === "AGENTE_HUB" ||
                    tipo_usuario === "AGENTE_ADMIN" ||
                    proceso === "INHOUSE" ||
                    proceso === "ENTRADAANDEN" ||
                    proceso === "MANIFIESTOAPP" ||
                    proceso === "RETORNOCLIENTES"
                  }
                  sx={{ width: "100%" }}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        "& li": { textAlign: "left" },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  isOptionEqualToValue={(option, value) => option.label === value}
                  renderInput={(params) => <TextField {...params} label="CIUDAD" />}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    width: "100%",
                    height: "90%",
                    backgroundColor: "#041562",
                  }}
                  onClick={handleFormSubmit}
                >
                  Buscar
                </Button>
              </Grid>
            )}
          </Grid>
          {tipo_usuario !== "CLIENTE" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} margin={"10px"}>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    width: "100%",
                    height: "90%",
                    backgroundColor: "#041562",
                  }}
                  onClick={handleFormSubmit}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          )}
          <div style={{ maxHeight: "650px", overflowY: "auto" }}>
            <List style={{ display: "flex", flexDirection: "column", padding: 0 }}>
              {manifiestos.map((manifiesto) => (
                <ListItem key={manifiesto.ID}>
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <IconButton
                      onClick={() => {
                        const url =
                          cod_cliente === "632" || manifiesto.ID_CLIENTE === 632
                            ? `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${
                                manifiesto.ID
                              }?useCod_Barra=${true}`
                            : cod_cliente === "463" || manifiesto.ID_CLIENTE === 463
                              ? `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDFBarCode/${
                                  manifiesto.ID
                                }?useBarcode=${true}`
                              : `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${
                                  manifiesto.ID
                                }?useBarcode=${false}`;

                        window.open(url, "_blank");
                      }}
                    >
                      <DescriptionIcon style={{ color: "#DA251C" }} />
                    </IconButton>
                    <span
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        fontSize: "10px",
                        color: "#DA251C",
                      }}
                    >
                      PDF
                    </span>
                  </div>

                  {(tipo_usuario !== "AGENTE" && proceso === "INHOUSE") ||
                  (tipo_usuario === "AGENTE" && proceso === "ENTRADAAGENTE") ? (
                    <div style={{ position: "relative", display: "inline-block", marginLeft: "8px" }}>
                      <IconButton
                        onClick={() => {
                          downloadExcel(manifiesto.ID, tipo_usuario);
                        }}
                      >
                        <DescriptionIcon style={{ color: "#3d834c" }} />
                      </IconButton>
                      <span
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          fontSize: "10px",
                          color: "#3d834c",
                        }}
                      >
                        EXCEL
                      </span>
                    </div>
                  ) : null}
                  <ListItemText
                    primary={`Manifiesto: ${manifiesto.ID}  / Creación: ${format(
                      new Date(manifiesto.FH_CREACION),
                      "dd-MM-yyyy",
                    )}`}
                    secondary={
                      manifiesto.PROCESO === "SALIDARUTA"
                        ? `CHOFER: ${manifiesto.CHOFER}`
                        : manifiesto.PROCESO === "ENTRADAAGENTE"
                          ? `ORIGEN: ${manifiesto.NOMBRE_ORIGEN}`
                          : manifiesto.PROCESO === "SALIDAANDEN"
                            ? `DESTINO: ${manifiesto.NOMBRE_DESTINO}`
                            : manifiesto.PROCESO === "SALIDAAGENTE"
                              ? `ORIGEN: ${manifiesto.NOMBRE_ORIGEN}`
                              : manifiesto.PROCESO === "INHOUSE"
                                ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE}`
                                : manifiesto.PROCESO === "ENTRADAANDEN"
                                  ? `USUARIO: ${manifiesto.NOMBRE_USUARIO}`
                                  : manifiesto.PROCESO === "RETORNOCLIENTES"
                                    ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE}`
                                    : manifiesto.PROCESO === "MANIFIESTOAPP"
                                      ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE}`
                                      : null
                    }
                    style={{ marginLeft: "10px" }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Paper>
      </form>
    </div>
  );
};
export default DescargaManifiestoPorProceso;
