import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Search, ManageSearch } from "@mui/icons-material";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useSelector } from "react-redux";

function MenuConsulta({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  const userInfo = useSelector((state) => state.user.userInfo);

  return (
    hasVisibleSubmenus([
      "ConsultaIndividual",
      "ConsultaMasivo",
      "ConsultaMasivoAgentes",
      "ConsultaMasivaAgentes",
      "ConsultaMasivaCliente",
      "ConsultaIndividualAgentes",
      "ConsultaPage",
      "ConsultaCedibles",
      "ConsultaIndividualClientesCodigoBarra",
    ]) && (
      <>
        <ListItemButton onClick={() => handleClick("consultas")}>
          <ListItemIcon sx={{ color: "white" }}>
            <SummarizeIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Consultas" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "consultas" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>
        <Collapse in={openSubMenu === "consultas"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isVisible("ConsultaIndividual") && (
              <ListItemButton
                component={Link}
                to="/ConsultaIndividual"
                sx={{
                  bgcolor: location.pathname === "/ConsultaIndividual" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaIndividual" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaIndividual" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaIndividual" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaIndividual" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaIndividual" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <Search />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta individual" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaIndividualAgentes") && (
              <ListItemButton
                component={Link}
                to="/ConsultaIndividualAgentes"
                sx={{
                  bgcolor: location.pathname === "/ConsultaIndividualAgentes" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaIndividualAgentes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaIndividualAgentes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaIndividualAgentes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaIndividualAgentes" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaIndividualAgentes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <Search />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta individual agentes" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaMasivo") && (
              <ListItemButton
                component={Link}
                to="/ConsultaMasivo"
                sx={{
                  bgcolor: location.pathname === "/ConsultaMasivo" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaMasivo" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaMasivo" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaMasivo" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaMasivo" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaMasivo" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <ManageSearch />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta masiva" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaMasivoAgentes") && (
              <ListItemButton
                component={Link}
                to="/ConsultaMasivoAgentes"
                sx={{
                  bgcolor: location.pathname === "/ConsultaMasivoAgentes" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaMasivoAgentes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaMasivoAgentes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaMasivoAgentes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaMasivoAgentes" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaMasivoAgentes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <ManageSearch />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta masiva agentes" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaMasivaAgentes") && (
              <ListItemButton
                component={Link}
                to="/ConsultaMasivaAgentes"
                sx={{
                  bgcolor: location.pathname === "/ConsultaMasivaAgentes" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaMasivaAgentes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaMasivaAgentes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaMasivaAgentes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaMasivaAgentes" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaMasivaAgentes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <ManageSearch />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta masiva agentes nueva" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaPage") && (
              <ListItemButton
                component={Link}
                to="/ConsultaPage"
                sx={{
                  bgcolor: location.pathname === "/ConsultaPage" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaPage" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaPage" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaPage" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaPage" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaPage" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <ManageSearch />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta masiva nueva (prueba)" />}
              </ListItemButton>
            )}
            {isVisible("ConsultaCedibles") && (
              <ListItemButton
                component={Link}
                to="/ConsultaCedibles"
                sx={{
                  bgcolor: location.pathname === "/ConsultaCedibles" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaCedibles" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaCedibles" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaCedibles" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaCedibles" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaCedibles" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <InsertPageBreakIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta cedibles" />}
              </ListItemButton>
            )}
            {userInfo.cod_cliente === "632"
              ? isVisible("ConsultaIndividualClientesCodigoBarra") && (
                  <ListItemButton
                    component={Link}
                    to="/ConsultaIndividualClientesCodigoBarra"
                    sx={{
                      bgcolor: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "white" : "transparent",
                      color: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "10px" : "0px",
                      margin: location.pathname === "/ConsultaIndividualClientesCodigoBarra" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <PersonSearchIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Consulta Individual Codigo de barra" />}
                  </ListItemButton>
                )
              : isVisible("ConsultaIndividualClientes") && (
                  <ListItemButton
                    component={Link}
                    to="/ConsultaIndividualClientes"
                    sx={{
                      bgcolor: location.pathname === "/ConsultaIndividualClientes" ? "white" : "transparent",
                      color: location.pathname === "/ConsultaIndividualClientes" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ConsultaIndividualClientes" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ConsultaIndividualClientes" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ConsultaIndividualClientes" ? "10px" : "0px",
                      margin: location.pathname === "/ConsultaIndividualClientes" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <PersonSearchIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Consulta individual cliente" />}
                  </ListItemButton>
                )}

            {isVisible("ConsultaMasivaCliente") && (
              <ListItemButton
                component={Link}
                to="/ConsultaMasivaCliente"
                sx={{
                  bgcolor: location.pathname === "/ConsultaMasivaCliente" ? "white" : "transparent",
                  color: location.pathname === "/ConsultaMasivaCliente" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ConsultaMasivaCliente" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ConsultaMasivaCliente" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ConsultaMasivaCliente" ? "10px" : "0px",
                  margin: location.pathname === "/ConsultaMasivaCliente" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <ManageSearch />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Consulta masiva cliente" />}
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuConsulta;
