import React, { useState, useMemo } from "react";
import { Box, TextField, Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { crearUsuarios } from "../../../services/PortalUsuariosService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateModalUsuarios from "./CreateModalUsuarios";

const TableUsuarios = ({ columns, rows, loading, reloadUsuarios }) => {
  const [searchText, setSearchText] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateSave = async (clienteData) => {
    try {
      await crearUsuarios(clienteData);
      toast.success("Usuario creado con éxito");
      setCreateModalOpen(false);
      reloadUsuarios();
    } catch (error) {
      toast.error("Error al crear el usuario");
      console.error("Error al crear el usuario:", error);
    }
  };
  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const nombre = row.name ? row.name.toLowerCase().includes(searchLowercase) : false;
      return nombre;
    });
  }, [searchText, rows]);

  return (
    <Box sx={{ height: 580, width: 1 }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}>
        <TextField
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          placeholder="Buscar por nombre..."
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#041562",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#031042",
            },
          }}
          onClick={() => setCreateModalOpen(true)}
        >
          Crear Usuarios
        </Button>
      </Box>
      <CreateModalUsuarios open={createModalOpen} onClose={() => setCreateModalOpen(false)} onSave={handleCreateSave} />
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableUsuarios;
