import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Autocomplete, Typography, Paper, CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  getChoferesXId,
  buscarDatosOrden,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTempManifiestoRuta,
  updateFHDigitacion,
  //downloadManifestPdfManifiestoEntrada,
} from "../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";

const ManifiestoIngresoBodega = () => {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;

  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [choferes, setChoferes] = useState([]);
  const [idChofer, setIdChofer] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [playsound] = useSound(sonido1);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [deshabilitar, setDeshabilitar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setProceso("ENTRADAANDEN");
      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        const choferesData = await getChoferesXId(id_user);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setChoferes(choferesOptions);
      } catch {}
    };
    fetchData();
  }, [id_user]);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 400 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 200 },
    { field: "COMUNA", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];
  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          /* setOrdenesDataTabla((prevOrdenes) => prevOrdenes.filter((o) => o.OD !== orden)); */
          const datos = {
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            PROCESO: proceso,
          };
          try {
            const resultados = await buscarDatosPickingTempManifiestoRuta(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                totalBultosTemp += orden.BULTOS;
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      // Convertir a fecha y hora local de Santiago de Chile
      const options = {
        timeZone: "America/Santiago",
        hour12: false, // Asegura formato de 24 horas
      };
      
      // Obtener la fecha en formato 'es-CL'
      const fecha = fechaActual.toLocaleDateString("es-CL", options);
      
      // Separar los elementos de la fecha (adaptar formato si es necesario)
      const [dia, mes, año] = fecha.split("-");
      
      // Crear la nueva fecha con el año primero
      const nuevaFecha = `${año}-${mes}-${dia}`;
      
      // Obtener la hora en formato de 24 horas
      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options, // Mantener la zona horaria
        hour12: false, // Asegura formato de 24 horas
      });
      
      // Concatenar fecha y hora
      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;
      console.log("Fecha Funcion:",fechaHoraFormateada);
      return fechaHoraFormateada;
      
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; // O cualquier otro manejo de errores que desees
    }
  };

  const resetState = () => {
    setNota("");
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setSelectedChofer(null);
    setTotalOrdenes("");
    setLoading(false);
    setIsbtnEnviar(false);
    setDeshabilitar(false);
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const handleChangeChoferes = async (event, newValue) => {
    try {
      setDeshabilitar(true);
      setSelectedChofer(newValue);
      const codi = newValue.value;
      if (codi !== null) {
        setIdChofer(newValue.value);

        const datos = {
          COD_USUARIO: id_user,
          COD_CHOFER: codi,
          PROCESO: proceso,
        };
        const dataPickingTemp = await buscarDatosPickingTempManifiestoRuta(datos);

        let totalBultosTemp = 0;
        if (dataPickingTemp && dataPickingTemp.results) {
          dataPickingTemp.results.forEach((orden) => {
            totalBultosTemp += orden.BULTOS;
          });
          setTotalOrdenes(dataPickingTemp.results.length);
        }

        setTotalBultos(totalBultosTemp);

        if (dataPickingTemp && dataPickingTemp.results) {
          setOrdenesDataTabla(dataPickingTemp.results);
        } else {
          setOrdenesDataTabla([]);
        }
      } else {
        alert("Seleccione un chofer");
      }
    } catch (error) {
      console.error("Error al obtener datos del backend:", error);
    }
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (!idChofer) {
          showError("Debe seleccionar un chofer!!!");
          return;
        }

        if (!ordenValue) {
          showError("Orden Vacia!!!");
          return;
        }

        const dataOrden = await buscarDatosOrden(ordenValue);

        if (!dataOrden || dataOrden.length === 0) {
          showError("Orden no existe!!!");
          return;
        }

        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

        if (ordenExistente) {
          playsound();
          inputRef.current.value = "";
          return;
        }

        const FH_DIGITACION = dataOrden[0].FH_DIGITACION;
        const formattedFH_DIGITACION = moment(FH_DIGITACION).format("YYYY-MM-DD");
        console.log("Fecha Orden Formateada.",formattedFH_DIGITACION);
        console.log("Fecha Actual: ",fechaActual);

        let responseUpdate;

        // Si la fecha es distinta a la actual y el COD_CLIENTE es 395, 2247 o 549, hacemos el update.
        if (
          formattedFH_DIGITACION !== fechaActual &&
          (dataOrden[0].COD_CLIENTE === 395 || dataOrden[0].COD_CLIENTE === 2247 || dataOrden[0].COD_CLIENTE === 549)
        ) {
          const datos = {
            OD: ordenValue,
            USUARIO: id_user,
          };

          // Llamada a la API para actualizar FH_DIGITACION
          responseUpdate = await updateFHDigitacion(datos);

          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";

          // Verificar si hay filas afectadas
          if (responseUpdate && responseUpdate.affectedRows > 0) {
            console.log("Filas afectadas:", responseUpdate.affectedRows);
          } else {
            console.log("No se actualizaron filas.");
          }

          //Si la fecha es igual o si paso por el update y afecto alguna fila.
        } else if (formattedFH_DIGITACION === fechaActual) {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
          return;
        } else {
          console.log("Fecha Las: ",formattedFH_DIGITACION);
          console.log("Fecha Actual: ", fechaActual);
          playsound();
          Swal.fire({
            title: "Fecha diferente",
            text: "La Fecha de la orden no coincide con la actual. ¿Deseas ingresarla igual?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              ingresarOrdenTabla(dataOrden);
              inputRef.current.value = "";
            } else {
              inputRef.current.value = "";
            }
          });
        }
      } catch (error) {
        console.error("Error al buscar los datos de la orden:", error);
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idChofer, ordenesDataTabla, ciudadUser],
  );

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {

        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            COMUNA: iataUser,
            PROCESO: proceso,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes(totalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const crearManifeisto = async () => {
    try {
      const fecha = getFecha();
      console.log(fecha);
      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: 0,
        COD_CHOFER: idChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: idChofer,
        COD_CHOFER_ENTRADA: idChofer,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: "SANTIAGO",
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: iataUser,
        ID_MANIFIESTO: response,
      };

      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(false);
    if (!idChofer) {
      showError("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (ordenesDataTabla.length === 0) {
      showError("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //setLoading(false);
          const numeroManifiesto = await crearManifeisto();
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          /* try {
            await downloadManifestPdfManifiestoEntrada(selectedChofer.label, numeroManifiesto);
          } catch (error) {
            showError("Error al descargar PDF!!!");
            return;
          } */
          resetState();
        }
        setLoading(false);
      });
    }
  };

  return (
    /*  */
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="ORIGEN MANIFIESTO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="RESPONSABLE"
                value={nombreUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={selectedChofer}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={choferes}
                onChange={handleChangeChoferes}
                disabled={deshabilitar}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CHOFER DE ENTRADA" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese OD"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default ManifiestoIngresoBodega;
