import React from 'react';

const EtiquetasParaImprimir = React.forwardRef(({ base64Images }, ref) => {
  return (
    <div ref={ref}>
      {base64Images.map((image, index) => (
        <div key={index} style={{ pageBreakAfter: 'always' }}>
          <img
            src={`data:image/png;base64,${image}`}
            alt={`Etiqueta ${index + 1}`}
            style={{ maxWidth: '100%' }}
          />
        </div>
      ))}
    </div>
  );
});

export default EtiquetasParaImprimir;
