import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ShowChart from "@mui/icons-material/ShowChart";
import InsertChartIcon from "@mui/icons-material/InsertChart";

function MenuGraficos({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  return (
    hasVisibleSubmenus(["ReportabilidadEjecutiva", "ReportabilidadGeneral"]) && (
      <>
        <ListItemButton onClick={() => handleClick("graficos")}>
          <ListItemIcon sx={{ color: "white" }}>
            <ShowChart />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="graficos" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "graficos" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>
        <Collapse in={openSubMenu === "graficos"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isVisible("ReportabilidadEjecutiva") && (
              <ListItemButton
                component={Link}
                to="/ReportabilidadEjecutiva"
                sx={{
                  bgcolor: location.pathname === "/ReportabilidadEjecutiva" ? "white" : "transparent",
                  color: location.pathname === "/ReportabilidadEjecutiva" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ReportabilidadEjecutiva" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ReportabilidadEjecutiva" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ReportabilidadEjecutiva" ? "10px" : "0px",
                  margin: location.pathname === "/ReportabilidadEjecutiva" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <InsertChartIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Reportabilidad ejecutiva" />}
              </ListItemButton>
            )}

            {isVisible("ReportabilidadGeneral") && (
              <ListItemButton
                component={Link}
                to="/ReportabilidadGeneral"
                sx={{
                  bgcolor: location.pathname === "/ReportabilidadGeneral" ? "white" : "transparent",
                  color: location.pathname === "/ReportabilidadGeneral" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ReportabilidadGeneral" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ReportabilidadGeneral" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ReportabilidadGeneral" ? "10px" : "0px",
                  margin: location.pathname === "/ReportabilidadGeneral" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <InsertChartIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Reportabilidad general" />}
              </ListItemButton>
            )}
            {isVisible("ReportabilidadAgentes") && (
              <ListItemButton
                component={Link}
                to="/ReportabilidadAgentes"
                sx={{
                  bgcolor: location.pathname === "/ReportabilidadAgentes" ? "white" : "transparent",
                  color: location.pathname === "/ReportabilidadAgentes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ReportabilidadAgentes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ReportabilidadAgentes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ReportabilidadAgentes" ? "10px" : "0px",
                  margin: location.pathname === "/ReportabilidadAgentes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <InsertChartIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Reportabilidad agentes" />}
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuGraficos;
