import React, { useState } from "react";
import { Paper, Grid, Button, Typography } from "@mui/material";
import CustomTextField from "../../utils/CustomTextField";
import { getNovofarmaPorRango } from "../../services/ReportesServices";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const DescargaNovofarmaRango = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const cod_cliente = userInfo ? userInfo.cod_cliente : null;
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaTermino, setFechaTermino] = useState("");

  const handleChangeFechaInicio = async (e) => {
    setFechaInicio(e.target.value);
  };

  const handleChangeFechaTermino = async (e) => {
    setFechaTermino(e.target.value);
  };

  const handleBuscarRango = async (e) => {
    downloadExcel();
  };

  const downloadExcel = async () => {
    const datos = {
      FECHAINICIO: fechaInicio,
      FECHATERMINO: fechaTermino,
    };
    const ordenesDataTabla = await getNovofarmaPorRango(datos);
    console.log(ordenesDataTabla);
    if (ordenesDataTabla.length > 0) {
      // Seleccionar solo las columnas necesarias y renombrar las cabeceras
      const filteredData = ordenesDataTabla.map((item) => ({
        Cliente: item.CLIENTE,
        OD: item.OD,
        OD_PAPEL: item.OD_PAPEL,
        Fecha: new Date(item.FH_DIGITACION).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        ID_REFERENCIA: item.ID_REFERENCIA,
        OBSERVACION: item.NOTA,
        NOMBRE: item.NOMBRE,
        DIRECCION: item.DIRECCION,
        RUT: item.RUT,
        GUIA: item.GUIA,
        "COMUNA ORIGEN": item.ORIGEN,
        "IATA ORIGEN PADRE": item.IATA_ORIGEN_PADRE,
        "COMUNA ORIGEN PADRE": item.COMUNA_ORIGEN_PADRE,
        "COMUNA DESTINO": item.DESTINO,
        "IATA DESTINO PADRE": item.IATA_DESTINO_PADRE,
        "COMUNA DESTINO PADRE": item.COMUNA_DESTINO_PADRE,
        BULTOS_TOTAL: item.BULTOS_TOTAL,
        PESO_TOTAL: item.PESO_TOTAL,
        LARGO: item.LARGO,
        ALTO: item.ALTO,
        ANCHO: item.ANCHO,
        CENTRO_COSTO: item.CENTRO_COSTO,
        TIPO_ORDEN: item.TIPO_ORDEN,
        "BULTOS FRIOS": item.BULTOS_FRIO,
        "PESO FRIOS": item.PESO_FRIO,
        "LARGO FRIOS": item.LARGO_FRIO,
        "ALTO FRIOS": item.ALTO_FRIO,
        "ANCHO FRIOS": item.ANCHO_FRIO,
        "TIPO FRIO": item.TIPO_FRIO,
        "BULTOS AMBIENTE": item.BULTOS_AMBIENTE,
        "PESO AMBIENTE": item.PESO_AMBIENTE,
        "LARGO AMBIENTE": item.LARGO_AMBIENTE,
        "ALTO AMBIENTE": item.ALTO_AMBIENTE,
        "ANCHO AMBIENTE": item.ANCHO_AMBIENTE,
        "TIPO AMBIENTE": item.TIPO_AMBIENTE,
        "LABORATORIO": item.LABORATORIO,
      }));

      // Crear una hoja de trabajo a partir de los datos filtrados
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      worksheet["!cols"] = [
        { wch: 30 }, //Cliente
        { wch: 15 }, //Od
        { wch: 15 }, //Od Papel
        { wch: 15 }, //Fecha
        { wch: 15 }, //Id Referencia
        { wch: 55 }, //Nombre
        { wch: 55 }, //Direccion
        { wch: 15 }, //Rut
        { wch: 15 }, //Guias
        { wch: 55 }, //Observaciones
        { wch: 20 }, //Comuna Origen
        { wch: 10 }, //Origen IATA PADRE
        { wch: 20 }, //Comuna Origen PADRE
        { wch: 20 }, //Comuna Destino
        { wch: 10 }, //Destino IATA PADRE
        { wch: 20 }, //Comuna Destino PADRE
        { wch: 15 }, //Bultos Total
        { wch: 15 }, //Peso Total
        { wch: 15 }, //ALTO
        { wch: 15 }, //LARGO
        { wch: 15 }, //ANCHO
        { wch: 20 }, //Centro_Costo
        { wch: 15 }, //Tipo_Orden
        { wch: 15 }, //Bultos Frios
        { wch: 15 }, //Peso Frios
        { wch: 15 }, //Largo Frios
        { wch: 15 }, //Alto Frios
        { wch: 15 }, //Ancho Frios
        { wch: 15 }, //TipoFrios
        { wch: 15 }, //Bultos Ambiente
        { wch: 15 }, //Peso Ambiente
        { wch: 15 }, //Alto Ambiente
        { wch: 15 }, //Largo Ambiente
        { wch: 15 }, //Ancho Ambiente
        { wch: 15 }, //Tipo Ambiente
        { wch: 20 }, //Laboratorio
      ];
      // Crear un nuevo libro de trabajo y añadir la hoja de trabajo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "HOJA1");

      // Descargar el archivo Excel
      XLSX.writeFile(workbook, `NOVOFARMA.xlsx`);
    } else {
      toast.error("Rango especificado no tiene datos.");
    }
  };

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px", marginTop: "10px" }}>
      <Typography variant="h6" align="center" gutterBottom>
        Busqueda por Rango de Fecha
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomTextField
            fullWidth
            type="date"
            size="small"
            label="Fecha Inicio"
            value={fechaInicio}
            onChange={handleChangeFechaInicio}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomTextField
            fullWidth
            type="date"
            size="small"
            label="Fecha Termino"
            value={fechaTermino}
            onChange={handleChangeFechaTermino}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            onClick={handleBuscarRango}
            fullWidth={true}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#031042",
              },
            }}
          >
            {cod_cliente === "549" ? "Descargar" : "Buscar"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DescargaNovofarmaRango;
