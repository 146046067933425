import React from "react";
import { Box } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";

const TableModificar = ({ columns, rows, loading }) => {
  return (
    <Box sx={{ height: 250, width: 1 }}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        getRowId={(row) => row.id}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableModificar;
