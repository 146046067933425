import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  actualizarUsuarios,
  actualizarPasswordUsuario,
  obtenerClientes,
  obtenerAgentesNegocios,
} from "../../../services/PortalUsuariosService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import { obtenerComunas } from "../../../services/EventosManualServices";

const EditModalUsuarios = ({ open, onClose, usuario, onSave, campos }) => {
  const [formData, setFormData] = useState({});
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [agentesNegocios, setAgentesNegocios] = useState([]);
  const [iataOptions, setIataOptions] = useState([]);

  useEffect(() => {
    setFormData(usuario ? { ...usuario, AGENTE_ASIGNADO: usuario.AGENTE_ASIGNADO || "" } : {});
  }, [usuario]);

  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const listaClientes = await obtenerClientes();
        setClientes(listaClientes);
        if (usuario && usuario.cod_cliente) {
          const clienteActual = listaClientes.find((c) => String(c.ID) === String(usuario.cod_cliente));
          setSelectedClient(clienteActual);
        } else {
          setSelectedClient(null);
        }
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
        toast.error("Error al cargar la lista de clientes.");
      }
    };
    cargarClientes();
  }, [usuario]);

  useEffect(() => {
    const cargarAgentesNegocios = async () => {
      try {
        const listaAgentes = await obtenerAgentesNegocios();
        setAgentesNegocios(listaAgentes);
      } catch (error) {
        console.error("Error al obtener los agentes de negocios:", error);
        toast.error("Error al cargar la lista de agentes de negocios.");
      }
    };
    cargarAgentesNegocios();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const cargarIataOptions = async () => {
      try {
        const comunas = await obtenerComunas();
        setIataOptions(comunas.map((comuna) => ({ code: comuna.IATA, name: comuna.NOMBRE })));
      } catch (error) {
        console.error("Error al cargar comunas:", error);
        toast.error("Error al cargar las comunas.");
      }
    };
    cargarIataOptions();
  }, []);

  const handleSave = async () => {
    const formDataConClienteId = selectedClient
      ? { ...formData, cod_cliente: selectedClient.ID }
      : { ...formData, cod_cliente: null };
    const choferId = formDataConClienteId.id;
    try {
      await actualizarUsuarios(choferId, formDataConClienteId);
      onSave(formDataConClienteId);
      toast.success("Usuario actualizado con éxito");
      onClose();
    } catch (error) {
      console.error("Error al guardar los cambios", error);
      toast.error("Error al actualizar el usuario");
    }
  };

  const handleEditPasswordClick = async () => {
    const campoPassword = formData.PASSWORD ? "PASSWORD" : formData.password_palabras ? "password_palabras" : null;
    const nuevaPassword = formData[campoPassword];
    if (!nuevaPassword || nuevaPassword.length < 6) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    try {
      const response = await actualizarPasswordUsuario(nuevaPassword);
      const { hash, token } = response;
      const campoHash = formData.PASSWORD_HASH ? "PASSWORD_HASH" : "password";
      const campoToken = "token";
      setFormData((prevFormData) => ({
        ...prevFormData,
        [campoHash]: hash,
        [campoToken]: token,
      }));
      toast.success("Hash y Token actualizados con éxito");
    } catch (error) {
      console.error("Error al actualizar el Hash y el Token:", error);
      toast.error("Error al actualizar el Hash y el Token");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Usuario</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {campos.map((campo, index) => (
            <Grid item xs={12} key={index}>
              {campo.nombre === "AGENTE_ASIGNADO" ? (
                <Autocomplete
                  id="combo-box-agente"
                  options={agentesNegocios}
                  getOptionLabel={(option) => option.NOMBRE_AGENTE}
                  value={
                    formData.AGENTE_ASIGNADO
                      ? agentesNegocios.find((option) => option.NOMBRE_AGENTE === formData.AGENTE_ASIGNADO)
                      : null
                  }
                  onChange={(event, newValue) => {
                    setFormData((prev) => ({ ...prev, AGENTE_ASIGNADO: newValue ? newValue.NOMBRE_AGENTE : "" }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Agente Asignado" variant="outlined" fullWidth />
                  )}
                />
              ) : campo.nombre === "cod_cliente" ? (
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes}
                  getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.ID})`}
                  isOptionEqualToValue={(option, value) => option.ID === value.ID}
                  value={selectedClient || null}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" fullWidth />}
                />
              ) : campo.nombre === "iata_sucursal" || campo.nombre === "iata_agentes" || campo.nombre === "CIUDAD" ? (
                <Autocomplete
                  id={`combo-box-${campo.nombre}`}
                  options={iataOptions}
                  getOptionLabel={(option) => option.name}
                  value={iataOptions.find((option) => option.code === formData[campo.nombre]) || null}
                  onChange={(event, newValue) => {
                    setFormData((prev) => ({ ...prev, [campo.nombre]: newValue ? newValue.code : "" }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={campo.etiqueta} variant="outlined" fullWidth />
                  )}
                />
              ) : campo.nombre === "PASSWORD_HASH" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "PASSWORD" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "token" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  disabled
                  variant="outlined"
                />
              ) : campo.nombre === "password_palabras" ? (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password edit" onClick={handleEditPasswordClick}>
                          <EditIcon style={{ color: "#041562" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : campo.nombre === "TRANSPORTISTA" ||
                campo.nombre === "PERMITE_RETIROS" ||
                campo.nombre === "permite_rebaja_manual" ||
                campo.nombre === "permite_rebaja_masiva" ||
                campo.nombre === "permite_modificacion_orden" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "ESTADO" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                    <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                  </Select>
                </FormControl>
              ) : campo.nombre === "tipo_usuario" ? (
                <FormControl fullWidth>
                  <InputLabel>{campo.etiqueta}</InputLabel>
                  <Select
                    label={campo.etiqueta}
                    name={campo.nombre}
                    value={formData[campo.nombre] || ""}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                    <MenuItem value="JEFATURA">JEFATURA</MenuItem>
                    <MenuItem value="CONTROL_CALIDAD">CONTROL DE CALIDAD</MenuItem>
                    <MenuItem value="SAC">SAC</MenuItem>
                    <MenuItem value="SUPERVISORA">SUPERVISORA</MenuItem>
                    <MenuItem value="OPERACIONES">OPERACIONES</MenuItem>
                    <MenuItem value="SUPERVISOR">SUPERVISOR OPERACIONES</MenuItem>
                    <MenuItem value="FACTURACION">FACTURACION</MenuItem>
                    <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                    <MenuItem value="AGENTE">AGENTE</MenuItem>
                    <MenuItem value="AGENTE_HUB">AGENTE HUB</MenuItem>
                    <MenuItem value="AGENTE_ADMIN">AGENTE ADMIN</MenuItem>
                    <MenuItem value="GRAFICOS">GRAFICOS</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  label={campo.etiqueta}
                  name={campo.nombre}
                  value={formData[campo.nombre] || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalUsuarios;
