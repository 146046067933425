import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { obtenerMarcasVehiculo, obtenerModelosVehiculo } from "../../../services/PortalVehiculosService";
import Autocomplete from "@mui/material/Autocomplete";

const EditModalClientes = ({ open, onClose, vehiculo, onSave }) => {
  const [formData, setFormData] = useState(vehiculo);
  const [marcas, setMarcas] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState(null);
  const [modelos, setModelos] = useState([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState(null);

  // Cargar datos del vehiculo y la marca al abrir el modal
  useEffect(() => {
    setFormData(vehiculo);
    if (vehiculo && marcas.length > 0) {
      const matchedMarca = marcas.find((c) => c.ID_MARCA === vehiculo.ID_MARCA);
      setMarcaSeleccionada(matchedMarca);
    }
  }, [vehiculo, marcas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Cargar las marcas
  useEffect(() => {
    const cargarMarcas = async () => {
      try {
        const marcasObtenidas = await obtenerMarcasVehiculo();
        setMarcas(marcasObtenidas);
      } catch (error) {
        console.error("Error al obtener marcas:", error);
      }
    };
    cargarMarcas();
  }, []);

  // Cargar los modelos cuando la marca cambia
  useEffect(() => {
    const cargarModelos = async () => {
      if (marcaSeleccionada) {
        try {
          const modelosObtenidos = await obtenerModelosVehiculo(marcaSeleccionada.ID_MARCA);
          setModelos(modelosObtenidos);

          // Establecer modelo seleccionado si coincide con el del vehículo actual
          if (vehiculo && vehiculo.ID_MODELO) {
            const modeloActual = modelosObtenidos.find((m) => m.ID_MODELO === vehiculo.ID_MODELO);
            setModeloSeleccionado(modeloActual);
          }
        } catch (error) {
          console.error("Error al obtener modelos:", error);
        }
      } else {
        setModelos([]);
        setModeloSeleccionado(null);
      }
    };
    cargarModelos();
  }, [marcaSeleccionada, vehiculo]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Vehiculo</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Patente"
          type="text"
          fullWidth
          variant="outlined"
          name="PATENTE"
          value={formData.PATENTE || ""}
          onChange={(e) => {
            const regex = /^[a-zA-Z0-9]*$/;
            if (regex.test(e.target.value)) {
              handleChange(e);
            }
          }}
        />
        <TextField
          margin="dense"
          label="Año Fabricacion"
          type="text"
          fullWidth
          variant="outlined"
          name="ANIO_FABRICACION"
          value={formData.ANIO_FABRICACION || ""}
          onChange={handleChange}
        />
        <Autocomplete
          id="combo-box-marca"
          options={marcas}
          getOptionLabel={(option) => option.NOMBRE_MARCA}
          isOptionEqualToValue={(option, value) => option.ID_MARCA === value.ID_MARCA}
          value={marcaSeleccionada}
          onChange={(event, newValue) => {
            setMarcaSeleccionada(newValue);
            setFormData((prev) => ({
              ...prev,
              ID_MARCA: newValue ? newValue.ID_MARCA : "",
              NOMBRE_MARCA: newValue ? newValue.NOMBRE_MARCA : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione Marca " variant="outlined" fullWidth margin="dense" />
          )}
        />
        <Autocomplete
          id="combo-box-modelo"
          options={modelos}
          getOptionLabel={(option) => option.NOMBRE_MODELO}
          isOptionEqualToValue={(option, value) => option.ID_MODELO === value.ID_MODELO}
          value={modeloSeleccionado}
          onChange={(event, newValue) => {
            setModeloSeleccionado(newValue);
            setFormData((prev) => ({
              ...prev,
              ID_MODELO: newValue ? newValue.ID_MODELO : "",
              NOMBRE_MODELO: newValue ? newValue.NOMBRE_MODELO : "",
            }));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccione Modelo" variant="outlined" fullWidth margin="dense" />
          )}
        />
        <TextField
          margin="dense"
          label="Capacidad de Carga"
          type="text"
          fullWidth
          variant="outlined"
          name="CAPACIDAD_CARGA"
          value={formData.CAPACIDAD_CARGA || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Kilometraje"
          type="text"
          fullWidth
          variant="outlined"
          name="KILOMETRAJE"
          value={formData.KILOMETRAJE || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Observaciones"
          type="text"
          fullWidth
          variant="outlined"
          name="OBSERVACIONES"
          value={formData.OBSERVACIONES || ""}
          onChange={handleChange}
        />
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel>Estado</InputLabel>
          <Select label="Estado" name="ESTADO" value={formData.ESTADO || ""} onChange={handleChange} variant="outlined">
            <MenuItem value="OPERATIVO">OPERATIVO</MenuItem>
            <MenuItem value="MANTENIMIENTO">MANTENIMIENTO</MenuItem>
            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: "5px" }} fullWidth>
          <InputLabel>Climatizacion</InputLabel>
          <Select
            label="Climatizacion"
            name="CLIMATIZACIONADO"
            value={formData.CLIMATIZACION || ""}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={() => onSave(formData)}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModalClientes;
