import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Paper, Typography, Avatar, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserProfileModal from "../../utils/UserProfileModal";

const InicioClientes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 1, minHeight: 100, position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
            <Typography
              variant="poster"
              sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1.5rem", flexGrow: 1, textAlign: "center" }}
              gutterBottom
            >
              BIENVENIDO/A {userInfo.name}
            </Typography>

            <IconButton onClick={handleOpenModal} sx={{ flexShrink: 0 }}>
              <Avatar>
                <AccountCircleIcon style={{ color: "#041562" }} />
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1 }}>
            <Typography
              sx={{ fontFamily: "'Nunito', sans-serif", fontSize: "1rem", textAlign: "center", width: "100%" }}
              gutterBottom
            >
              Si necesitas algún requerimiento de informática, puedes enviar un correo electrónico a este correo:{" "}
              <span style={{ color: "blue" }}>ti@cargoex.cl</span> para poder recibir soporte.
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 1, mt: 2 }}>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/e5Rps0wHpJc"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Paper>
        <UserProfileModal open={openModal} handleClose={handleCloseModal} userInfo={userInfo} />
      </Grid>
    </Grid>
  );
};

export default InicioClientes;
