import React, { useEffect, useCallback, useState, memo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { obtenerClientes, subirCedibleManualmente, buscarCedible } from "../../services/CediblesService";
import { useDropzone } from "react-dropzone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Grid, Chip, TextField, Card, CardContent, Box } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

const CediblesPage = () => {
  const [failedFiles, setFailedFiles] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [guideNumber, setGuideNumber] = useState("");
  const [manualFile, setManualFile] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const [uploadProgress, setUploadProgress] = useState({ loaded: 0, total: 0 });
  const [fileName, setFileName] = useState("");
  
  const handleOpenModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsUploadModalOpen(false);
  };

  /* enviar manualmente */
  const handleManualUpload = async () => {
    if (!selectedClient || !selectedClient.id) {
      toast.error("Por favor, selecciona un cliente.");
      return;
    }
    const formData2 = new FormData();
    formData2.append("file", manualFile);
    formData2.append("guia", guideNumber);
    formData2.append("clientId", selectedClient.id);
    formData2.append("fecha", new Date().toISOString());
    try {
      const result = await subirCedibleManualmente(formData2);
      console.log("Respuesta del servidor:", result);
      toast.success("Cédible subido manualmente con éxito");
      setIsUploadModalOpen(false);
      setSelectedClient(null);
      setGuideNumber("");
      setManualFile(null);
      setInputKey(Date.now());
    } catch (error) {
      console.error("Error al subir el cédible manualmente:", error);
      toast.error("Error al subir el cédible manualmente");
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => file.name.endsWith(".jpeg") || file.name.endsWith(".jpg"));
    if (validFiles.length === 0) {
      toast.error("Por favor, selecciona archivos con extensiones .jpeg o .jpg solamente.");
      return;
    }
    const filesWithId = validFiles.map((file) => ({
      id: Date.now() + file.name,
      file,
    }));
    setFiles((prevFiles) => [...prevFiles, ...filesWithId]);
  }, []);

  const removeFile = (fileId) => {
    setFiles(files.filter((file) => file.id !== fileId));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileUpload = async () => {
    if (files.length === 0) {
      toast.error("Por favor, selecciona archivos para cargar.");
      return;
    }
    if (!selectedClient || !selectedClient.id) {
      toast.error("Por favor, selecciona un cliente.");
      return;
    }
    setIsLoading(true);
    setUploadProgress({ loaded: 0, total: files.length });
    let failedFilesTemp = [];
    let successCount = 0;
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i];
      const formData = new FormData();
      formData.append("files", fileItem.file);
      formData.append("clientId", selectedClient.id);
      try {
        const result = await buscarCedible(formData);
        if (result.failedFiles && result.failedFiles.length > 0) {
          failedFilesTemp = [...failedFilesTemp, ...result.failedFiles];
        }
        if (result.successCount) {
          successCount += result.successCount;
        }
        setUploadProgress((prevProgress) => ({
          loaded: prevProgress.loaded + 1,
          total: files.length,
        }));
      } catch (error) {
        console.error("Error al cargar el archivo:", fileItem.file.name, error);
        failedFilesTemp.push(fileItem.file.name);
      }
    }
    if (failedFilesTemp.length > 0) {
      setFailedFiles(failedFilesTemp);
      setIsErrorModalOpen(true);
    }
    if (successCount > 0) {
      toast.success(`${successCount} archivos se enviaron con éxito`);
    } else if (failedFilesTemp.length === 0) {
      toast.success("Todos los archivos se han cargado con éxito");
    }
    setFiles([]);
    setSelectedClient(null);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientes();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  return (
    <>
      <Card
        sx={{
          width: "100%",
          margin: "0 auto",
          padding: "20px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <Grid item xs={12} sm={3} md={4}>
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes.map((cliente) => ({
                    id: cliente.ID,
                    nombre: cliente.NOMBRE + " (ID: " + cliente.ID + ")",
                  }))}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={selectedClient}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nombre Cliente"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    fullWidth
                    margin="dense"
                    size="small"
                    variant="contained"
                    sx={{
                      backgroundColor: "#041562",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#031042",
                      },
                      marginLeft: "10px",
                      height: "40px",
                      width: "100%",
                      maxWidth: "180px",
                    }}
                    onClick={handleFileUpload}
                  >
                    Enviar Cedibles
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#041562",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#031042",
                      },
                      height: "40px",
                      width: "100%",
                      maxWidth: "180px",
                    }}
                    onClick={handleOpenModal}
                  >
                    Envío manual
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #e0e0e0",
                  borderRadius: "10px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon style={{ fontSize: "48px", color: "#757575" }} />
                <p>Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos</p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {files.map((fileItem) => (
                  <Grid item key={fileItem.id}>
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={fileItem.file.name}
                      onDelete={() => removeFile(fileItem.id)}
                      color="primary"
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Dialog
              open={isUploadModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="md"
            >
              <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ padding: 20 }}>
                <Grid item xs={12} container spacing={2} alignItems="center">
                  <Grid item xs={12} md={9}>
                    <TextField
                      fullWidth
                      label="Número de Guía"
                      value={guideNumber}
                      onChange={(e) => setGuideNumber(e.target.value)}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      onClick={handleManualUpload}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        backgroundColor: "#041562",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#031042",
                        },
                        height: "100%",
                      }}
                    >
                      Enviar
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{
                      width: "100%",
                      height: "45px",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      color: "black",
                      borderColor: "#4727ce",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                        borderColor: "#311b92",
                      },
                    }}
                  >
                    Seleccionar archivo
                    <input
                      key={inputKey}
                      type="file"
                      accept=".jpeg, .jpg"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        padding: 0,
                      }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setManualFile(file);
                          setFileName(file.name);
                        }
                      }}
                      hidden
                    />
                    <Typography
                      variant="body2"
                      sx={{ marginLeft: 2, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {fileName || "Ningun archivo seleccionado"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={isLoading}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            color: "white",
            margin: "auto",
            padding: "20px",
          }}
        >
          <CircularProgress
            size={80}
            sx={{
              color: "white",
              marginBottom: "20px",
            }}
          />
          <p>
            Cargando {uploadProgress.loaded}/{uploadProgress.total} archivos...
          </p>
        </Box>
      </Dialog>
      <Dialog open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <DialogTitle>Error al procesar archivos</DialogTitle>
        <DialogContent>
          <Typography>Los siguientes archivos no se pudieron cargar:</Typography>
          {failedFiles.map((file, index) => (
            <Typography key={index}>- {file}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsErrorModalOpen(false)} color="primary" variant="contained">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(CediblesPage);
