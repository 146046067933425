import React, { useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import "../../assets/modalEdicion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { buscarCTC, createOrderAPI, getClientAddresses } from "../../../services/ordenesServices";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { Grid, Button, Container, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { es } from "date-fns/locale";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Fab from "@mui/material/Fab";
import CustomTextField from "../../../utils/CustomTextField";
import CustomAutocomplete from "../../../utils/CustomAutocomplete";

function CreateComponent({ dataAll }) {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [clientAddresses, setClientAddresses] = useState([]);
  const [guias, setGuias] = useState([]);
  const [documentoActual, setDocumentoActual] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const direccionesUnicas = Array.from(new Set(clientAddresses));
  const [shouldReload, setShouldReload] = useState(false);
  const [dimensions, setDimensions] = useState({ ALTO: 0, LARGO: 0, ANCHO: 0 });
  const [clientDetails, setClientDetails] = useState({ CANALES: [], TIPOS_NEGOCIO: [], CENTROS_COSTO: [] });
  const { TODAS_CUIDADES, TODOS_LOS_CLIENTES } = dataAll;
  const [isEditing, setIsEditing] = useState(false);
  const [editedDocumentIndex, setEditedDocumentIndex] = useState(null);
  const [editedDocumentValue, setEditedDocumentValue] = useState("");
  const tiposDeOrden = ["ENTREGA", "RETIRO"];
  const vias = ["AEREO", "TERRESTRE"];
  const tiposDeCarga = ["AMBIENTE", "FRIO", "BOLSAS", "CAJA", "REFRIGERADO", "SOBRE", "PALLET"];

  /* formato de la fecha */
  const formatDateToText = (dateObj) => {
    if (dateObj instanceof Date) {
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();
      return `${year}/${month}/${day}`;
    }
    return null;
  };

  const [formData, setFormData] = useState({
    OD_PAPEL: "",
    ID_REFERENCIA: "",
    RUT: "",
    TELEFONO: "",
    MAIL: "",
    DIRECCION: "",
    ALTO: "",
    ANCHO: "",
    LARGO: "",
    PESO: "",
    PESO_VOLUMEN: "",
    TIPO_CARGA: "",
    CENTRO_COSTO: "",
    TIPO_NEGOCIO: "",
    CANAL: "",
    FH_DIGITACION: formatDateToText(new Date()),
    COD_CLIENTE: "",
    BULTOS: "",
    TIPO_ORDEN: "",
    NOTA: "",
    NOMBRE: "",
    NOMBRE_CLIENTE: "",
    CIUDAD_ORIGEN: "",
    CIUDAD_DESTINO: "",
    VIA: "",
    GUIA: "",
  });

  /* generador de id referencia */
  const generadorReferencia = () => {
    let date = new Date();
    var TEXT =
      date.getFullYear() +
      "" +
      (date.getMonth() + 1) +
      "" +
      date.getDate() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();

    setFormData((prevState) => ({ ...prevState, ID_REFERENCIA: TEXT }));
  };

  /* cambios de los documentos */
  const handleInputChangeDocument = (e) => {
    setDocumentoActual(e.target.value);
  };

  /* cambios de los inputs */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /* cambios en fecha */
  const handleDateChange = (newValue) => {
    const newDate = formatDateToText(newValue);
    if (newDate) {
      setFormData((prev) => ({
        ...prev,
        FH_DIGITACION: newDate,
      }));
    }
  };

  /* dimensiones */
  const handleDimensionChange = (e) => {
    const { name, value } = e.target;
    setDimensions((prevState) => ({
      ...prevState,
      [name]: parseFloat(value),
    }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /* volumen */
  const calculateVolume = () => {
    if (
      isNaN(dimensions.ALTO) ||
      isNaN(dimensions.LARGO) ||
      isNaN(dimensions.ANCHO) ||
      dimensions.ALTO === "" ||
      dimensions.LARGO === "" ||
      dimensions.ANCHO === ""
    ) {
      return "";
    }
    const volume = (dimensions.ALTO * dimensions.LARGO * dimensions.ANCHO) / 4000;
    return volume.toFixed(3);
  };

  const computeVolume = useCallback(() => {
    return ((dimensions.ALTO * dimensions.LARGO * dimensions.ANCHO) / 4000).toFixed(3);
  }, [dimensions.ALTO, dimensions.LARGO, dimensions.ANCHO]);

  /* cambios en cliente */
  const handleClientChange = async (idCliente) => {
    // Actualizar el estado con el ID del cliente seleccionado
    setFormData((prev) => ({
      ...prev,
      COD_CLIENTE: idCliente,
    }));
    try {
      const details = await buscarCTC({ idCliente });
      setClientDetails(details);
      const addresses = await getClientAddresses(idCliente);
      setClientAddresses(addresses);
    } catch (error) {
      console.error("Error al obtener detalles del cliente:", error);
    }
  };

  /* resetear datos */
  const resetFormData = async () => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar campos",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      setFormData({
        OD_PAPEL: "",
        ID_REFERENCIA: "",
        RUT: "",
        TELEFONO: "",
        MAIL: "",
        DIRECCION: "",
        ALTO: "",
        ANCHO: "",
        LARGO: "",
        PESO: "",
        PESO_VOLUMEN: "",
        TIPO_CARGA: "",
        CENTRO_COSTO: "",
        TIPO_NEGOCIO: "",
        CANAL: "",
        FH_DIGITACION: formatDateToText(new Date()),
        COD_CLIENTE: "",
        BULTOS: "",
        TIPO_ORDEN: "",
        NOTA: "",
        NOMBRE: "",
        VIA: "",
        GUIA: "",
      });
      setGuias([]);
      setDocumentoActual("");
      setDimensions({ ALTO: "", LARGO: "", ANCHO: "" });
      setSelectedAddress(""); // Reseteado a valor por defecto
      setAddressInput("");
    }
  };

  /* ingreso de documento */
  const handleIngresarDocumento = () => {
    if (!documentoActual.trim()) {
      toast.error("El documento no puede estar vacío.");
      return;
    }
    setGuias((prevGuias) => {
      const newGuias = [...prevGuias, documentoActual.trim()];
      setFormData((prev) => ({
        ...prev,
        GUIA: newGuias.join(" - "),
      }));
      return newGuias;
    });
    setDocumentoActual("");
  };

  /* eliminacion de documento */
  const handleEliminarDocumento = (indexToRemove) => {
    const updatedGuias = guias.filter((_, index) => index !== indexToRemove);
    setGuias(updatedGuias);
    setFormData((prev) => ({
      ...prev,
      GUIA: updatedGuias.join(" - "),
    }));
  };
  /* poner fecha del dia anterior */
  const getYesterdayDateText = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };
  /* mensaje predeterminado para los campos que van si o si */
  const getMissingFieldsMessage = () => {
    const fields = {
      NOMBRE: "Nombre",
      DIRECCION: "Dirección",
      ID_REFERENCIA: "ID de referencia",
      COD_CLIENTE: "Código del cliente",
      TIPO_ORDEN: "Tipo de orden",
      BULTOS: "Bultos",
      PESO: "Peso",
      ALTO: "Alto",
      LARGO: "Largo",
      ANCHO: "Ancho",
    };
    const missingFields = Object.entries(fields)
      .filter(([key]) => !formData[key])
      .map(([key, label]) => label);

    if (missingFields.length === 0) return null;
    if (missingFields.length === 1) return `Por favor, complete el campo: ${missingFields[0]}.`;
    return `Por favor, complete los campos: ${missingFields.join(", ")}.`;
  };
  /* creacion de ordenes */
  const handleCreateOrder = async () => {
    for (let key in formData) {
      if (typeof formData[key] === "string") {
        formData[key] = formData[key].toUpperCase();
      }
    }
    formData.PESO = parseFloat(formData.PESO).toFixed(2);
    formData.DIRECCION = selectedAddress || addressInput;
    const errorMessage = getMissingFieldsMessage();
    if (errorMessage) {
      toast.error(errorMessage);
      return;
    }
    const filteredFormData = Object.fromEntries(Object.entries(formData).filter(([key, value]) => value !== ""));
    const finalFormData = {
      ...filteredFormData,
      FECHA_DIGITACION: filteredFormData.FH_DIGITACION || getYesterdayDateText(),
      USUARIO: id_usuario,
      COMUNA: filteredFormData.CIUDAD_DESTINO,
      COMUNA_ORIGEN: filteredFormData.CIUDAD_ORIGEN,
    };
    try {
      const response = await createOrderAPI(finalFormData);
      if (response && response.success) {
        Swal.fire({
          title: "Orden creada con éxito",
          text: `Orden creada con el número: ${response.od}`,
          icon: "success",
          confirmButtonText: "Cerrar",
        });
        setShouldReload(true);
      } else {
        Swal.fire({
          title: "Error",
          text: response.msj || "Error al crear la orden.",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#041562",
        });
      }
    } catch (error) {
      toast.error("Error al crear la orden. Por favor, inténtalo de nuevo.");
    }
  };

  /* validacion aparte de la OD papel */
  const validateOD_PAPEL = () => {
    const value = formData.OD_PAPEL;
    if (value && !/^\d{6,}$/.test(value)) {
      toast.error("OD Papel debe ser numérico, sin espacios y tener 6 o más caracteres.");
    }
  };

  /* cambios de od papel */
  const handleOD_PAPELChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      OD_PAPEL: value,
    }));
  };

  /* cambios de bultos */
  const handleBULTOSChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value < 1) {
      toast.error("Bultos debe ser mayor o igual a 1.");
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      BULTOS: value,
    }));
  };

  const updateVolumeInState = useCallback(() => {
    const volume = computeVolume();
    setFormData((prev) => ({
      ...prev,
      PESO_VOLUMEN: volume,
    }));
  }, [computeVolume]);

  const clearFormData = useCallback(() => {
    setFormData({
      OD_PAPEL: "",
      ID_REFERENCIA: "",
      RUT: "",
      TELEFONO: "",
      MAIL: "",
      DIRECCION: "",
      ALTO: "",
      ANCHO: "",
      LARGO: "",
      PESO: "",
      PESO_VOLUMEN: "",
      TIPO_CARGA: "",
      CENTRO_COSTO: "",
      TIPO_NEGOCIO: "",
      CANAL: "",
      FH_DIGITACION: formatDateToText(new Date()),
      COD_CLIENTE: "",
      BULTOS: "",
      TIPO_ORDEN: "",
      NOTA: "",
      NOMBRE: "",
      VIA: "",
      GUIA: "",
      NOMBRE_CLIENTE: "",
    });
    setGuias([]);
    setDocumentoActual("");
    setDimensions({ ALTO: "", LARGO: "", ANCHO: "" });
    setSelectedAddress("");
    setAddressInput("");
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleIngresarDocumento();
      event.preventDefault();
    }
  };

  /* editar documento en la tabla */
  const handleEditarDocumento = (index) => {
    setEditedDocumentIndex(index);
    setEditedDocumentValue(guias[index]);
    setIsEditing(true);
  };

  /* guardar lo editado en la tabla */
  const handleSaveEditedDocument = async (e) => {
    e.preventDefault();
    if (editedDocumentIndex !== null) {
      const newGuias = [...guias];
      newGuias[editedDocumentIndex] = editedDocumentValue;
      setGuias(newGuias);
      setFormData((prev) => ({
        ...prev,
        GUIA: newGuias.join(" - "),
      }));
      setIsEditing(false);
      setEditedDocumentIndex(null);
      setEditedDocumentValue("");
    }
  };

  useEffect(() => {
    updateVolumeInState();
  }, [dimensions.ALTO, dimensions.LARGO, dimensions.ANCHO, updateVolumeInState]);

  useEffect(() => {
    if (shouldReload) {
      clearFormData();
      setShouldReload(false);
    }
  }, [shouldReload, clearFormData]);

  return (
    <Container maxWidth="lg">
      {/* Datos de Orden Section */}
      <Typography variant="h6" gutterBottom>
        Datos orden
      </Typography>
      <Grid container spacing={1}>
        {/* First Row */}
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="OD"
            name="OD"
            variant="outlined"
            value={formData.OD || ""}
            margin="dense"
            size="small"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="OD PAPEL"
            variant="outlined"
            value={formData.OD_PAPEL || ""}
            onChange={handleOD_PAPELChange}
            onBlur={validateOD_PAPEL}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <div style={{ position: "relative" }}>
            <CustomTextField
              name="ID_REFERENCIA"
              label="ID Referencia"
              value={formData.ID_REFERENCIA || ""}
              onChange={handleInputChange}
              margin="dense"
              size="small"
              variant="outlined"
            />
            <button
              type="button"
              onClick={generadorReferencia}
              style={{
                background: "none",
                border: "none",
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Comuna Origen"
            options={TODAS_CUIDADES.map((ciudad) => `${ciudad.IATA} - ${ciudad.NOMBRE}`)}
            getOptionLabel={(option) => option}
            value={formData.CIUDAD_ORIGEN || null}
            onChange={(event, newValue) => {
              const iataCode = newValue ? newValue.split(" ")[0].trim() : "";
              setFormData((prev) => ({
                ...prev,
                CIUDAD_ORIGEN: iataCode,
              }));
            }}
            isOptionEqualToValue={(option, value) => option.startsWith(value)}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Comuna Destino"
            options={TODAS_CUIDADES.map((ciudad) => `${ciudad.IATA} - ${ciudad.NOMBRE}`)}
            getOptionLabel={(option) => option}
            value={formData.CIUDAD_DESTINO || null}
            onChange={(event, newValue) => {
              const iataCode = newValue ? newValue.split(" ")[0].trim() : "";
              setFormData((prev) => ({
                ...prev,
                CIUDAD_DESTINO: iataCode,
              }));
            }}
            isOptionEqualToValue={(option, value) => option.startsWith(value)}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
              label="Fecha de Digitación"
              format="dd/MM/yyyy"
              value={formData.FH_DIGITACION ? new Date(formData.FH_DIGITACION) : new Date(getYesterdayDateText())}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  margin: "dense",
                  variant: "outlined",
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Clientes"
            options={TODOS_LOS_CLIENTES}
            getOptionLabel={(option) => option.NOMBRE}
            value={TODOS_LOS_CLIENTES.find((cliente) => cliente.NOMBRE === formData.NOMBRE_CLIENTE) || null}
            onChange={(event, newValue) => {
              if (newValue) {
                handleClientChange(newValue.COD_CLIENTE);
                setFormData((prev) => ({
                  ...prev,
                  NOMBRE_CLIENTE: newValue.NOMBRE,
                }));
              } else {
                setFormData((prev) => ({
                  ...prev,
                  NOMBRE_CLIENTE: "",
                }));
              }
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Canales"
            options={clientDetails.CANALES || []}
            getOptionLabel={(option) => option}
            value={formData.CANAL || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                CANAL: newValue,
              }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de negocio"
            options={clientDetails.TIPOS_NEGOCIO || []}
            getOptionLabel={(option) => option}
            value={formData.TIPO_NEGOCIO || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                TIPO_NEGOCIO: newValue,
              }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Centro costo"
            freeSolo
            options={clientDetails.CENTROS_COSTO}
            getOptionLabel={(option) => option}
            value={formData.CENTRO_COSTO || ""}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                CENTRO_COSTO: newValue || "",
              }));
            }}
            inputValue={formData.CENTRO_COSTO || ""}
            onInputChange={(event, newInputValue) => {
              setFormData((prev) => ({
                ...prev,
                CENTRO_COSTO: newInputValue || "",
              }));
            }}
            fullWidth
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de orden"
            options={tiposDeOrden}
            getOptionLabel={(option) => option}
            value={formData.TIPO_ORDEN || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                TIPO_ORDEN: newValue,
              }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Via"
            options={vias}
            getOptionLabel={(option) => option}
            value={formData.VIA || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                VIA: newValue,
              }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de carga"
            options={tiposDeCarga}
            getOptionLabel={(option) => option}
            value={formData.TIPO_CARGA || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                TIPO_CARGA: newValue,
              }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Correo"
            name="MAIL"
            value={formData.MAIL || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Nota"
            name="NOTA"
            value={formData.NOTA || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Remitente Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Remitente
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Nombre retiro"
            name="NOMBRE_RETIRO"
            value={formData.NOMBRE_RETIRO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Dirección retiro"
            name="DIRECCION_RETIRO"
            value={formData.DIRECCION_RETIRO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Contacto retiro"
            name="CONTACTO"
            value={formData.CONTACTO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Teléfono retiro"
            name="TELEFONO_ORIGEN"
            value={formData.TELEFONO_ORIGEN || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Destinatario Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Destinatario
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Rut"
            name="RUT"
            value={formData.RUT || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Nombre"
            name="NOMBRE"
            value={formData.NOMBRE || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Autocomplete
            sx={{ width: "100%" }}
            id="address-combo-box"
            options={direccionesUnicas}
            getOptionLabel={(option) => option}
            value={selectedAddress || ""}
            onChange={(event, newValue) => setSelectedAddress(newValue || "")}
            inputValue={addressInput || ""}
            onInputChange={(event, newInputValue) => {
              setAddressInput(newInputValue || "");
            }}
            freeSolo
            renderInput={(params) => (
              <CustomTextField {...params} margin="dense" label="Dirección" variant="outlined" size="small" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Teléfono"
            name="TELEFONO"
            value={formData.TELEFONO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Contacto destino"
            name="CONTACTO_DESTINO"
            value={formData.CONTACTO_DESTINO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Dimensiones Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Dimensiones
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Bultos"
            name="BULTOS"
            value={formData.BULTOS || ""}
            onChange={handleBULTOSChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Peso"
            name="PESO"
            step="0.01"
            value={formData.PESO || ""}
            onChange={handleInputChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Alto"
            name="ALTO"
            value={formData.ALTO || ""}
            onChange={handleDimensionChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Largo"
            name="LARGO"
            value={formData.LARGO || ""}
            onChange={handleDimensionChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Ancho"
            name="ANCHO"
            value={formData.ANCHO || ""}
            onChange={handleDimensionChange}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Volumen"
            name="VOLUMEN"
            value={calculateVolume()}
            readOnly
            margin="dense"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Documentos Section */}
      <Grid container spacing={1} style={{ marginTop: "5px" }}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="N.º Documento"
            variant="outlined"
            value={documentoActual || ""}
            onKeyDown={handleKeyDown}
            onChange={handleInputChangeDocument}
            margin="dense"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleIngresarDocumento}
            style={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              marginTop: 8,
            }}
          >
            Ingresar
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Documento</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {guias.map(
                (guia, index) =>
                  guia.trim() && (
                    <tr key={index}>
                      <td>
                        {isEditing && editedDocumentIndex === index ? (
                          <input
                            type="text"
                            value={editedDocumentValue}
                            onChange={(e) => setEditedDocumentValue(e.target.value)}
                          />
                        ) : (
                          guia.trim()
                        )}
                      </td>
                      <td>
                        {isEditing && editedDocumentIndex === index ? (
                          <Button
                            type="button"
                            onClick={handleSaveEditedDocument}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #041562",
                              color: "#041562",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                            aria-label="Guardar cambios"
                          >
                            Guardar
                          </Button>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={() => handleEliminarDocumento(index)}
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                              }}
                              aria-label="Eliminar documento"
                            >
                              <FontAwesomeIcon icon={faTrash} color="red" />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleEditarDocumento(index)}
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                              }}
                              aria-label="Editar documento"
                            >
                              <FontAwesomeIcon icon={faEdit} color="#041562" />
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </Table>
        </Grid>
      </Grid>

      {/* Botones */}
      <Grid container spacing={1}>
        <Fab
          color="secondary"
          aria-label="cancel"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 100,
            backgroundColor: "#DA251C",
            "&:hover": {
              backgroundColor: "#C12F1F",
            },
            zIndex: 1000,
          }}
          onClick={resetFormData}
        >
          <CancelIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: "#041562",
            "&:hover": {
              backgroundColor: "#031042",
            },
            zIndex: 1000,
          }}
          onClick={handleCreateOrder}
        >
          <AddIcon />
        </Fab>
      </Grid>
    </Container>
  );
}

export default CreateComponent;
