import React, { useState } from "react";
import { Box, Paper, Button, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { downloadManifestPdfGlobal, generarManifiestoPDF_UserBarCode } from "../../../services/ManifiestosServices";
import * as XLSX from "xlsx";
import { getManifiestoXID } from "../../../services/ReportesServices";

const DescargaManifiestoPorId = () => {
  const [manifiestoId, setManifiestoId] = useState("");
  const [pdfData, setPdfData] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [disableChecked] = useState(false);

  const handleChange = (event) => {
    setManifiestoId(event.target.value);
  };

  const handleGenerarClick = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }

    if (isChecked === true) {
      const pdfUrl = await generarManifiestoPDF_UserBarCode(manifiestoId, true);
      setPdfData(pdfUrl);
      toast.success("PDF generado correctamente");
    } else {
      try {
        const pdfUrl = await downloadManifestPdfGlobal(manifiestoId);
        setPdfData(pdfUrl);
        toast.success("PDF generado correctamente");
      } catch (error) {
        console.error("Hubo un error al obtener el PDF", error);
        toast.error("Error al generar el PDF");
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  React.useEffect(() => {
    return () => {
      if (pdfData) {
        URL.revokeObjectURL(pdfData);
      }
    };
  }, [pdfData]);

   const downloadExcel = async () => {
    const datos = {
      manifiestoID: manifiestoId
    };
    const ordenesDataTabla = await getManifiestoXID(datos);
     if (ordenesDataTabla.length > 0) {
      // Seleccionar solo las columnas necesarias y renombrar las cabeceras
      const filteredData = ordenesDataTabla.map((item) => ({
        OD: item.OD,
        GUIA: item.GUIA,
        ID_REF: item.ID_REFERENCIA,
        CLIENTE: item.CLIENTE_NOMBRE,
        DESTINATARIO: item.NOMBRE,
        DIRECCION: item.DIRECCION,
        DESTINO: item.COMUNA_DESTINO_NOMBRE,
        BULTOS:item.BULTOS

      }));

      // Crear una hoja de trabajo a partir de los datos filtrados
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      worksheet["!cols"] = [
        { wch: 10 },
        { wch: 15 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 25 },
        { wch: 15 },
  
      ];
      // Crear un nuevo libro de trabajo y añadir la hoja de trabajo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "HOJA1");

      // Descargar el archivo Excel
      XLSX.writeFile(workbook, `MANIFIESTO_${manifiestoId}.xlsx`);
    } else {
      toast.error("Número de manifiesto invalido.");
    } 
  }; 

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <TextField
            label="Número de Manifiesto"
            variant="outlined"
            value={manifiestoId}
            type="search"
            size="small"
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button
            variant="contained"
            onClick={handleGenerarClick}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#030e4f",
              },
              mb: 2,
            }}
          >
            Generar PDF
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button
            variant="contained"
            onClick={downloadExcel}
            sx={{
              backgroundColor: "#3d834c",
              color: "#FFFFFF",
              "&:hover": {  
                backgroundColor: "#375a35",
              },
              mb: 2,
            }}
          >
            Generar EXCEL
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
            label="CODIGO BARRA E ID"
            disabled={disableChecked}
          />
        </Grid>
      </Grid>

      {pdfData && (
        <Box
          sx={{
            width: "100%",
            height: "500px",
            border: "1px solid #ccc",
            mt: 1,
          }}
        >
          <iframe src={pdfData} width="100%" height="100%" title="Manifiesto PDF">
            Tu navegador no soporta iframes.
          </iframe>
        </Box>
      )}
    </Paper>
  );
};

export default DescargaManifiestoPorId;