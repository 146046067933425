import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, Paper, Autocomplete, TextField, Tooltip } from "@mui/material";
import Tabla from "../../components/DespachoComponentes/componentesRetirosIntegracion/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import {
  obtenerClientesRetiro,
  buscarIataCiudad,
  insertOdRetiro,
  obtenerOD,
} from "../../services/RetirosIntegracionServices";
import {
  toastDatosEnBlanco,
  ordenNoCreada,
  alertaComunasOrigenToast,
  excelCargado,
  excelVacio,
  sinCliente,
  alertaOrdenes,
} from "../../components/DespachoComponentes/componentesRetirosIntegracion/Alertas";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivoAdmin,
  limiteDeFilas,
  LinearProgressOverlay,
  ValidarHomologacionCiudades,
} from "../../components/DespachoComponentes/componentesRetirosIntegracion/Funciones";
import "../../components/DespachoComponentes/componentesRetirosIntegracion/retiros.css";
import { ExcelDownloadLinkStgo } from "../../components/DespachoComponentes/componentesRetirosIntegracion/DownExcel";
import { useSelector } from "react-redux";

const RetirosSantiago = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isAutocompleteEnabledCli, setIsAutocompleteEnabledCli] = useState(false);
  const [isAutocompleteEnabledFh, setIsAutocompleteEnabledFh] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(true);
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");
  const [odCount, setOdCount] = useState(0);

  const predefinedHeaders = [
    "OD",
    "NOMBRE CLIENTE",
    "DIRECCION",
    "TELEFONO",
    "CIUDAD",
    "CENTRO DE COSTO",
    "DESCRIPCION DE CARGA",
    "CONTACTO",
    "BULTO",
    "KILO",
    "GUIA",
  ];

  const obligatoriasHeaders = ["OD", "NOMBRE CLIENTE", "DIRECCION", "CIUDAD"];

  const handleInputCliente = async (event, newValue) => {
    try {
      setCodigoCliente(newValue.value);
      const codi = newValue.value;
      if (codi !== null) {
        try {
          setSelectedOption(newValue);
        } catch {}
      } else {
      }
    } catch (error) {}
  };

  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };

  const ObtenerClientes = async () => {
    try {
      const dataAll = await obtenerClientesRetiro();

      const clientOptions = dataAll.map((cliente) => ({
        value: cliente.ID,
        label: cliente.NOMBRE,
      }));
      setOptions(clientOptions);
    } catch (error) {
      console.error("Error fetching dataAll:", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    }
  };

  useEffect(() => {
    ObtenerClientes();
  }, []);

  const handleChangeFecha = async (e) => {
    setFechaSeleccionada(e.target.value);
  };

  const cargaExcelTabla = async (e) => {
    if (!codigoCliente) {
      sinCliente();
      fileInputRef.current.value = "";
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            if (ValidadorArchivoAdmin(headersMatch, codigoCliente, fechaSeleccionada)) {
              Papa.parse(csvData, {
                complete: async (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });
                      return obj;
                    })
                    .filter((obj) => Object.values(obj).some((val) => val !== ""));

                  setData(dataSinObjetosVacios);

                  const jsonDataArray = [];
                  parsedData.data.forEach((row, index) => {
                    const rowData = {
                      FH_DIGITACION: fechaSeleccionada,
                      COD_CLIENTE: codigoCliente,
                      ID_REFERENCIA: row["OD"],
                      OD: row["OD"],
                      OD_PAPEL: row["OD"],
                      NOMBRE: row["NOMBRE CLIENTE"],
                      DIRECCION: row["DIRECCION"],
                      GUIA: row["GUIA"],
                      COMUNA: "SCL",
                      NOTA: `${row["DESCRIPCION DE CARGA"]} / ${row["CONTACTO"]}`,
                      TELEFONO: row["TELEFONO"],
                      BULTOS: row["BULTO"],
                      PESO: row["KILO"],
                      CENTRO_COSTO: row["CENTRO DE COSTO"]?.trim() || "", // Si está vacío o con espacios en blanco, asigna ""
                      TIPO_ORDEN: "RETIRO",
                      COMUNA_ORIGEN: row["CIUDAD"],
                      ORIGEN_ORDEN: "PORTAL RETIROS INTEGRACION",
                      ORIGEN_BODEGA: false,
                      VIA: row[""],
                      USUARIO: id_usuario,
                      CANAL: row["CENTRO DE COSTO"]?.trim() || "", // Asigna el valor limpio también a CANAL
                      TIPO_NEGOCIO: row["CENTRO DE COSTO"]?.trim() || "",
                    };

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        setIsbtnEnviar(false);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      } else {
                        resolve();
                      }
                    }
                  }

                  const odCount = {};
                  const duplicateODs = [];
                  parsedData.data.forEach((row, index) => {
                    if (row["OD"]) {
                      if (odCount[row["OD"]]) {
                        odCount[row["OD"]]++;
                        duplicateODs.push(row["OD"]);
                      } else {
                        odCount[row["OD"]] = 1;
                      }
                    }
                  });

                  if (duplicateODs.length > 0) {
                    const uniqueDuplicateODs = [...new Set(duplicateODs)];
                    const errorMessage = `Las siguientes OD están duplicadas en el archivo excel: ${uniqueDuplicateODs.join(", ")}`;
                    toast.error(errorMessage);
                    setIsbtnEnviar(false);
                    e.target.value = "";
                    setData([]);
                    setJsonDataArray([]);
                    return;
                  }

                  const guiaFormatErrors = [];
                  parsedData.data.forEach((row, index) => {
                    const guias = row["GUIA"];
                    if (guias) {
                      const guiaParts = guias.split(" - ");
                      const isValidFormat =
                        guiaParts.every((part) => part.trim().length > 0) && // Todas las partes deben tener contenido
                        guiaParts.length >= 1 &&
                        guiaParts.length <= 20 && // "guia - guia - guia - guia - guia"
                        guias.match(/^([^ -]+( - [^ -]+)*)$/); // Asegurar que el formato sea correcto
                      if (!isValidFormat) {
                        guiaFormatErrors.push(index + 2);
                      }
                    }
                  });

                  if (guiaFormatErrors.length > 0) {
                    const errorMessage = `Filas con formato incorrecto en columna GUIA: la Fila ${guiaFormatErrors.join(
                      ", ",
                    )} No tiene el formato correcto`;
                    toast.error(errorMessage);
                    setIsbtnEnviar(false);
                    e.target.value = "";
                    setData([]);
                    setJsonDataArray([]);
                    return;
                  }

                  try {
                    const comunasInvalidasOrigen = [];
                    const ordenesExistente = [];

                    const totalFilas = jsonDataArray.length;
                    let filaExcel = 0;
                    setIsVisible(true);

                    await Promise.all(
                      jsonDataArray.map(async (rowData) => {
                        const parsedRow = JSON.parse(rowData);
                        try {
                          const data = await obtenerOD(parsedRow.OD);
                          if (data.success) {
                            ordenesExistente.push(parsedRow.OD);
                          }
                          await ValidarHomologacionCiudades(parsedRow.COMUNA_ORIGEN, comunasInvalidasOrigen);
                          filaExcel++;
                          const newProgress = (filaExcel / totalFilas) * 100;
                          setProgress(newProgress);
                        } catch (error) {
                          console.error("Error al validar orden existente:", error);
                        }
                      }),
                    );

                    if (ordenesExistente.length > 0) {
                      alertaOrdenes(ordenesExistente);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (comunasInvalidasOrigen.length > 0) {
                      const newProgress = (filaExcel / totalFilas) * 100;
                      setProgress(newProgress);
                      if (newProgress === 100) {
                        setCompleted(true);
                      }
                      alertaComunasOrigenToast(comunasInvalidasOrigen);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (jsonDataArray.length === 0) {
                      excelVacio();
                      fileInputRef.current.value = "";
                    } else {
                      setJsonDataArray(jsonDataArray);
                      const odCount = parsedData.data.filter((row) => row).length;
                      setOdCount(odCount);
                      setIsAutocompleteEnabledCli(true);
                      setIsAutocompleteEnabledFh(true);
                      setIsbtnEnviar(false);
                      excelCargado();
                    }
                  } catch (error) {
                    console.error("Error en el procesamiento de datos:", error);
                  }

                  setProgress(100);
                  setIsVisible(false);
                  setProgress(0);
                  setCompleted(false);
                },
                header: true,
              });
            } else {
              setIsbtnEnviar(true);
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const resetState = () => {
    setData([]);
    setJsonDataArray([]);
    setSelectedOption(null);
    setCodigoCliente("");
    setFechaSeleccionada("");
    setIsAutocompleteEnabledCli(false);
    setIsAutocompleteEnabledFh(false);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    setIsbtnEnviar(true);
    setOdCount(0);
    fileInputRef.current.value = "";
  };

  const Cancelar = async () => {
    resetState();
  };

  const tooltipMessageGuias =
    "Por favor, asegúrece de que el archivo Excel que suba cumpla con el formato en la columna DOCUMENTOS siguiendo el patrón: N°guía - N°guía (si hay más de una).";

  const obtenerIatasParaComunas = async (jsonDataArray) => {
    try {
      const iatasPorComuna = {};

      const totalOrders = jsonDataArray.length;
      let createdOrdersCount = 0;
      // Itera sobre cada objeto JSON en el array
      for (const jsonData of jsonDataArray) {
        // Parsea el objeto JSON
        const parsedData = JSON.parse(jsonData);
        // Obtiene la COMUNA ORIGEN del objeto
        const comunaOrigen = parsedData.COMUNA_ORIGEN;
        // Llama a la función buscarIataCiudad para obtener el IATA correspondiente
        const iata = await buscarIataCiudad(comunaOrigen);

        createdOrdersCount++;
        const newProgress = (createdOrdersCount / totalOrders) * 50;
        setProgress(newProgress);
        // Almacena la IATA en un objeto usando la COMUNA ORIGEN como clave
        iatasPorComuna[comunaOrigen] = iata;
      }
      // Retorna un objeto con las IATAs correspondientes a cada COMUNA ORIGEN
      return iatasPorComuna;
    } catch (error) {
      // Maneja errores si es necesario
      console.error("Error obteniendo IATAs por comuna:", error);
      throw error;
    }
  };

  const crearOrdenes = async () => {
    setIsVisible(true);
    try {
      const totalOrders = jsonDataArray.length;
      let createdOrdersCount = 0;
      const createdOrders = [];
      const iatasPorComuna = await obtenerIatasParaComunas(jsonDataArray);

      await Promise.all(
        jsonDataArray.map(async (jsonData) => {
          const parsedData = JSON.parse(jsonData);
          const comunaOrigen = parsedData.COMUNA_ORIGEN;
          const iataOrigen = iatasPorComuna[comunaOrigen];

          parsedData.COMUNA_ORIGEN = iataOrigen;

          const jsonDataWithIata = JSON.stringify(parsedData);
          const response = await insertOdRetiro(jsonDataWithIata);

          createdOrdersCount++;
          const newProgress = 50 + (createdOrdersCount / totalOrders) * 50;
          setProgress(newProgress);

          if (response) {
            createdOrders.push(response);
          }
        }),
      );

      setProgress(100);
      if (createdOrders.length > 0) {
        Swal.fire({
          title: "Retiros creados con éxito",
          text: "Todas las órdenes se han creado exitosamente.",
          icon: "success",
          confirmButtonText: "Cerrar",
        });
        setIsVisible(false);
        setProgress(0);
        setCompleted(false);

        setTimeout(() => {
          setKey(Date.now());
          fileInputRef.current.value = "";
          setData([]);
          setJsonDataArray([]);
          resetState();
        }, 2000);
      } else {
        ordenNoCreada();
      }
    } catch (error) {
      console.error("Error al crear las órdenes:", error);
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo1"
              onChange={handleInputCliente}
              options={options}
              value={selectedOption}
              renderInput={(params) => <TextField {...params} label="CLIENTE" />}
              disabled={isAutocompleteEnabledCli}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="Fecha"
              value={fechaSeleccionada}
              onChange={handleChangeFecha}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isAutocompleteEnabledFh}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={"5px"} alignItems="flex-end">
          <Grid item xs={12} sm={4}>
            <Tooltip title={tooltipMessageGuias} arrow placement="bottom">
              <div className="same-size-container">
                <Form.Group controlId="formFile">
                  <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    key={key}
                    onChange={cargaExcelTabla}
                    onClick={LimpiaArray}
                    size="sm"
                  />
                </Form.Group>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{ backgroundColor: "#041562", color: "#FFFFFF" }}
              className="boton1"
              size="medium"
              variant="contained"
              color="primary"
              onClick={crearOrdenes}
              disabled={isbtnEnviar}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{ backgroundColor: "#DA251C" }}
              className="boton1"
              size="medium"
              variant="contained"
              onClick={Cancelar}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <h3>Órdenes Cargadas: {odCount}</h3>
          </Grid>
          <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
            <ExcelDownloadLinkStgo></ExcelDownloadLinkStgo>
          </Grid>
        </Grid>
        <div>
          <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
        </div>
        {data.length > 0 && (
          <Paper
            style={{
              width: "100%",
              maxWidth: "100%",
              overflow: "auto",
              maxHeight: "75vh",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Tabla
              data={data}
              headers={headers}
              onCpdateellU={(rowIndex, header, newValue) => {
                const newData = [...data];
                newData[rowIndex][header] = newValue;
                setData(newData);
              }}
            />
          </Paper>
        )}
      </Paper>
    </div>
  );
};

export default RetirosSantiago;
