import React, { memo, useState, useEffect } from "react";
import { obtenerUsuarios, eliminarUsuarios } from "../../services/PortalUsuariosService";
import TableUsuarios from "../../components/AdministracionComponentes/componentesUsuarios/tableUsuarios";
import EditModalUsuarios from "../../components/AdministracionComponentes/componentesUsuarios/EditModalUsuarios";
import DeleteModalUsuarios from "../../components/AdministracionComponentes/componentesUsuarios/DeleteModalUsuarios";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { camposUsuarios } from "../../components/AdministracionComponentes/componentesUsuariosChoferes/ConfigCampos";

const PortalUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUsuarioForEdit, setSelectedUsuarioForEdit] = useState(null);
  const [selectedUsuarioForDelete, setSelectedUsuarioForDelete] = useState(null);

  useEffect(() => {
    const cargarUsuarios = async () => {
      setLoading(true);
      try {
        const data = await obtenerUsuarios();
        setUsuarios(data);
      } catch (error) {
        console.error("Error al obtener los Usuarios:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarUsuarios();
  }, []);

  const onEditClick = (usuario) => {
    setSelectedUsuarioForEdit(usuario);
    setEditModalOpen(true);
  };

  const onDeleteClick = (usuario) => {
    setSelectedUsuarioForDelete(usuario);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await eliminarUsuarios(selectedUsuarioForDelete.id);
      const filteredUsuarios = usuarios.filter((usuario) => usuario.id !== selectedUsuarioForDelete.id);
      setUsuarios(filteredUsuarios);
      setDeleteModalOpen(false);
      toast.success("Usuario eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el Usuario:", error);
      toast.error("Error al eliminar el Usuario");
    }
  };

  const handleSaveEdit = (updatedUsuario) => {
    const newUsuarios = usuarios.map((usuario) => (usuario.id === updatedUsuario.id ? updatedUsuario : usuario));
    setUsuarios(newUsuarios);
    setEditModalOpen(false);
  };

  const columns = [
    {
      field: "acciones",
      headerName: "Acciones",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => onEditClick(params.row)}>
            <EditIcon style={{ color: "#041562" }} />
          </IconButton>
          <IconButton onClick={() => onDeleteClick(params.row)}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
      width: 100,
    },
    { field: "id", headerName: "ID", width: 60 },
    { field: "name", headerName: "Nombre usuario", width: 300 },
    { field: "email", headerName: "Correo", width: 250 },
    { field: "tipo_usuario", headerName: "Tipo de usuario", width: 130 },
    { field: "cod_cliente", headerName: "Cliente", width: 100 },
    { field: "password_palabras", headerName: "Password", width: 130 },
    { field: "password", headerName: "Password hash", width: 300 },
    { field: "token", headerName: "Token", width: 220 },
    { field: "canal", headerName: "Canal", width: 150 },
    { field: "iata_sucursal", headerName: "Iata sucursal", width: 140 },
    { field: "iata_agentes", headerName: "Iata agentes", width: 100 },
    { field: "ESTADO", headerName: "Estado", width: 100 },
    { field: "permite_rebaja_manual", headerName: "Permite rebaja manual?", width: 180 },
    { field: "permite_rebaja_masiva", headerName: "Permite rebaja masiva?", width: 180 },
    { field: "permite_modificacion_orden", headerName: "Permite modificacion orden?", width: 210 },
  ];

  useEffect(() => {
    const cargarUsuarios = async () => {
      setLoading(true);
      try {
        const data = await obtenerUsuarios();
        setUsuarios(data);
      } catch (error) {
        console.error("Error al obtener los Usuarios:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarUsuarios();
  }, []);

  const reloadUsuarios = async () => {
    setLoading(true);
    try {
      const data = await obtenerUsuarios();
      setUsuarios(data);
    } catch (error) {
      console.error("Error al recargar los usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TableUsuarios columns={columns} rows={usuarios} loading={loading} reloadUsuarios={reloadUsuarios} />
      {selectedUsuarioForEdit && (
        <EditModalUsuarios
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedUsuarioForEdit(null);
          }}
          usuario={selectedUsuarioForEdit}
          onSave={handleSaveEdit}
          campos={camposUsuarios}
        />
      )}
      {selectedUsuarioForDelete && (
        <DeleteModalUsuarios
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            setSelectedUsuarioForDelete(null);
          }}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default memo(PortalUsuarios);
