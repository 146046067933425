import React, { useState, useRef } from "react";
import { EtiquetaObtener } from "../../services/ordenesServices";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import EtiquetasParaImprimir from "../../components/FacturacionComponentes/componentesEtiqueta/EtiquetasParaImprimir";
import { Box, Typography, TextField, Button } from "@mui/material";

const ImprimirEtiqueta = () => {
  const [orderNumber, setOrderNumber] = useState("");
  const [base64Images, setBase64Images] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const contentRef = useRef(null);

  const handleObtenerEtiqueta = async () => {
    if (!orderNumber) {
      toast.error("Por favor, ingresa un número de orden válido.");
      return;
    }
    setLoading(true);
    try {
      const etiquetaData = await EtiquetaObtener(orderNumber);
      if (etiquetaData && etiquetaData.etiqueta && etiquetaData.etiqueta.length > 0) {
        setBase64Images(etiquetaData.etiqueta);
        setCurrentIndex(0);
      } else {
        toast.error("No se encontraron etiquetas para este número de orden.");
      }
    } catch (error) {
      toast.error("Error al obtener las etiquetas. Por favor, inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: `Etiquetas_OD_${orderNumber}`,
    onAfterPrint: () => toast.success("Impresión completada."),
  });

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < base64Images.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Número de Orden"
          variant="outlined"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          disabled={loading}
        />
        <Button
          variant="contained"
          onClick={handleObtenerEtiqueta}
          disabled={loading}
          sx={{ ml: 2, backgroundColor: "#041562", '&:hover': { backgroundColor: "#030e3c" } }}
        >
          {loading ? "Cargando..." : "Obtener Etiqueta"}
        </Button>
      </Box>
      {base64Images.length > 0 && (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center" my={2}>
            <Button
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              Anterior
            </Button>
            <Typography variant="body1" sx={{ mx: 2 }}>
              {currentIndex + 1} / {base64Images.length}
            </Typography>
            <Button
              onClick={handleNext}
              disabled={currentIndex === base64Images.length - 1}
            >
              Siguiente
            </Button>
          </Box>
          {/* Mostrar la etiqueta actual */}
          <Box textAlign="center">
            <img
              src={`data:image/png;base64,${base64Images[currentIndex]}`}
              alt={`Etiqueta ${currentIndex + 1}`}
              style={{ maxWidth: "100%" }}
            />
          </Box>
          {/* Componente para imprimir */}
          <div style={{ position: 'absolute', left: '-10000px', top: 0 }}>
            <EtiquetasParaImprimir ref={contentRef} base64Images={base64Images} />
          </div>
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              onClick={reactToPrintFn}
              sx={{ backgroundColor: "#041562", '&:hover': { backgroundColor: "#030e3c" } }}
            >
              Imprimir Etiqueta(s)
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImprimirEtiqueta;
